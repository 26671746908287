import React, {useState, useEffect} from 'react'
import Sidebar from '../../Sidebar'
// Header
import Header from '../../Header'
// Footer
import Footer from '../../Footer'
import {Modal,Button} from 'react-bootstrap'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as MyConstants from '../../../../Constant/Config'
import { useHistory } from 'react-router-dom'
import axios from 'axios'

export default function EditProducts({id}) 
{
    // Get ProductsId Data 
    const [Products, setProducts] = useState([]);
    // Edit Products
    const [EditProducts, setEditProducts] = useState([]);
    // Get Brands Data 
    const [Brands, setBrands] = useState([]);
    // Get Categories Data 
    const [Categories, setCategories] = useState([]);
    // Get SubCategories Data 
    const [SubCategories, setSubCategories] = useState([]);
    // Get Currency Data 
    const [Currency, setCurrency] = useState([]);
    // Get Units Data 
    const [Units, setUnits] = useState([]);
    // Get Stores Data 
    const [Stores, setStores] = useState([]);

    const url = window.location.pathname;
    const split = url.split('/');
    console.log(split[2]);

    useEffect(() => {

        // EditProducts
        fetch(MyConstants.editProduct + split[2],
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((result) => 
        {
            result.json().then((response) => 
            {
                console.warn(response);
                if(response.status == true)
                {
                    setBrands(response.products.brand);
                    setCategories(response.products.category);
                    setSubCategories(response.products.sub_category);
                    setCurrency(response.products.currency);
                    setProducts(response.products.products);
                    setUnits(response.products.unit);
                    setStores(response.products.store);
                }
            })
        });
    },[]);


    const History = useHistory();
    const NavigateTo = () => History.push('/list-products');

    const [image, setImage] = useState("");
    // const [ Edit, setEdit ] = useState(
    // {
    //     "alert_quantity": Products.alert_quantity,
    //     "detail": Products.detail,
    //     "unit": Products.unit,
    //     "name": Products.name,
    //     "quantity": Products.quantity,
    //     "sub_category": Products.sub_category,
    //     "type": Products.type,
    //     "price": Products.price,
    //     "brand": Products.brand,
    //     "cost": Products.cost,
    //     "code": Products.code,
    //     "store_id": Products.store_id,
    //     "currency": Products.currency,
    //     "parent_category": Products.parent_category
    // });
    
    const onInputEditValue = (e) =>
    {
        
        setProducts({...Products, [e.target.name]: e.target.value});
    };

    const UpdateData = () =>
    {
        console.log(Products);
        const formData =  new FormData();
		formData.append('id',Products.id);
		formData.append('name',Products.name);
        formData.append('code',Products.code); 
        formData.append('brand',Products.brand); 
        formData.append('price',Products.price); 
        formData.append('type',Products.type); 
        formData.append('quantity',Products.quantity); 
        formData.append('alert_quantity',Products.alert_quantity); 
        formData.append('sub_category',Products.sub_category); 
        formData.append('cost',Products.cost);
        formData.append('detail',Products.detail);
        formData.append('store_id',Products.store_id);
        formData.append('currency',Products.currency);
        formData.append('unit',Products.unit);
        formData.append('parent_category',Products.category);
        formData.append('image',image);
		axios.post(MyConstants.updateProduct,formData)
		.then((result)=>{
			if(result.data.status == true){
                console.log(result.data.message);
                NavigateTo();
            }
		})
    }

    return (
        <div>
            <div>
                <div className="nk-app-root">
                    {/* main @s */}
                    <div className="nk-main ">
                        {/* sidebar @s */}
                        <div className="nk-sidebar nk-sidebar-fixed is-light " data-content="sidebarMenu">
                            <Sidebar />
                        </div>
                        {/* Sidebar @e */}
                        {/* wrap @s */}
                        <div className="nk-wrap ">
                            {/* main header @s */}
                            <div className="nk-header nk-header-fixed is-light">
                                {/* Header */}
                                <Header />
                            </div>
                            {/* main header @e */}

                            {/* content @s */}
                            <div className="nk-content ">
                                <div className="container-fluid">
                                    <div className="nk-content-inner">
                                        <div className="nk-content-body">
                                            <div class="components-preview">
                                                <div className="nk-block-head nk-block-head-sm card p-4" style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', outline: 'none', marginTop: '20px' }}>
                                                    <div className="nk-block-between">
                                                        <div className="nk-block-head-content">
                                                            <h3 className="nk-block-title page-title" style={{ color: "#9d72ff" }}>Edit Products</h3>
                                                        </div>{/* .nk-block-head-content */}
                                                        <div className="nk-block-head-content">
                                                            <Button className="btn btn-primary btn-sm d-none d-md-inline-flex" style={{ backgroundColor: "#9d72ff", border: "#9d72ff", outline: "none", boxShadow: "none" }}  onClick={NavigateTo}><em className="icon ni ni-plus" /><span>Products List</span></Button>
                                                        </div>{/* .nk-block-head-content */}
                                                    </div>{/* .nk-block-between */}
                                                </div>{/* .nk-block-head */}
                                                <div className="nk-block nk-block-lg mt-5">
                                                    <div className="card card-preview" style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', outline: 'none', marginTop: '20px' }}>
                                                        <div className="card-inner">
                                                            <div className='row'>
                                                                <div className='col-6'>
                                                                    <label className="form-label" htmlFor="">Name</label>
                                                                    <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                                                                    <input type="text" className="form-control" name="name" placeholder="Enter Product Name" defaultValue={Products.name} onChange={onInputEditValue} required />
                                                                </div>
                                                                <div className='col-6'>
                                                                    <label className="form-label" htmlFor="">Type</label>
                                                                    <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                                                                    <input type="text" className="form-control" name="type" placeholder="Enter Product Type" defaultValue={Products.type} onChange={onInputEditValue}  required />
                                                                </div>
                                                            </div>
                                                            <div className='row mt-3'>
                                                                <div className='col-6'>
                                                                    <label className="form-label" htmlFor="">Code</label>
                                                                    <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                                                                    <input type="text" className="form-control" name="code" placeholder="Enter Product Code" defaultValue={Products.code} onChange={onInputEditValue}  required />
                                                                </div>
                                                                <div className='col-6'>
                                                                    <label className="form-label" htmlFor="">Brand</label>
                                                                    <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                                                                    <select className='form-control custom-select' name="brand" onChange={onInputEditValue}>
                                                                    { Brands.map(item =>
                                                                        Products.brand == item.id ? (<option value={item.id} selected>{item.name}</option>) : (<option value={item.id}>{item.name}</option>)
                                                                    )}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className='row mt-3'>
                                                                <div className='col-6'>
                                                                    <label className="form-label" htmlFor="">Category</label>
                                                                    <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                                                                    <select className='form-control custom-select' name="category" onChange={onInputEditValue}>
                                                                    { Categories.map(item =>
                                                                        Products.category == item.id ? (<option value={item.id} selected>{item.name}</option>) : (<option value={item.id}>{item.name}</option>)
                                                                    )}
                                                                    </select>
                                                                </div>
                                                                <div className='col-6'>
                                                                    <label className="form-label" htmlFor="">Sub Category</label>
                                                                    <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                                                                    <select className='form-control custom-select' name="sub_category" onChange={onInputEditValue}>
                                                                    { SubCategories.map(item =>
                                                                        Products.sub_category == item.id ? (<option value={item.id} selected>{item.name}</option>) : (<option value={item.id}>{item.name}</option>)
                                                                    )}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className='row mt-3'>
                                                                <div className='col-6'>
                                                                    <label className="form-label" htmlFor="">Currency</label>
                                                                    <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                                                                    <select className='form-control custom-select' name="currency" onChange={onInputEditValue}>
                                                                    { Currency.map(item =>
                                                                        Products.currency == item.id ? (<option value={item.id} selected>{item.name}</option>) : (<option value={item.id}>{item.name}</option>)
                                                                    )}
                                                                    </select>
                                                                </div>
                                                                <div className='col-6'>
                                                                    <label className="form-label" htmlFor="">Units</label>
                                                                    <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                                                                    <select className='form-control custom-select' name="unit" onChange={onInputEditValue}>
                                                                    { Units.map(item =>
                                                                        Products.unit == item.id ? (<option value={item.id} selected>{item.name}</option>) : (<option value={item.id}>{item.name}</option>)
                                                                    )}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className='row mt-3'>
                                                                <div className='col-6'>
                                                                    <label className="form-label" htmlFor="">Price</label>
                                                                    <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                                                                    <input type="number" className="form-control" name="price" placeholder="Enter Product Price" defaultValue={Products.price} onChange={onInputEditValue}  required />
                                                                </div>
                                                                <div className='col-6'>
                                                                    <label className="form-label" htmlFor="">Alert Quantity</label>
                                                                    <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                                                                    <input type="number" className="form-control" name="alert_quantity" placeholder="Enter Alert Quantity" onChange={onInputEditValue} defaultValue={Products.alert_quantity}  required />
                                                                </div>
                                                            </div>
                                                            <div className='row mt-3'>
                                                                <div className='col-6'>
                                                                    <label className="form-label" htmlFor="">Quantity</label>
                                                                    <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                                                                    <input type="number" className="form-control" name="quantity" placeholder="Enter Product Quantity" onChange={onInputEditValue} defaultValue={Products.quantity}  required />
                                                                </div>
                                                                <div className='col-6'>
                                                                    <label className="form-label" htmlFor="">Stores</label>
                                                                    <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                                                                    <select className='form-control custom-select' name="store_id" onChange={onInputEditValue}>
                                                                    { Stores.map(item =>
                                                                        Products.store_id == item.id ? (<option value={item.id} selected>{item.name}</option>) : (<option value={item.id}>{item.name}</option>)
                                                                    )}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className='row mt-3'>
                                                                <div className='col-6'>
                                                                    <label className="form-label" htmlFor="">Details</label>
                                                                    <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                                                                    <input type="text" className="form-control" name="detail" placeholder="Enter Product Details" onChange={onInputEditValue} defaultValue={Products.detail}  required />
                                                                </div>
                                                                <div className='col-6'>
                                                                    <label >Image</label>
                                                                    <input type="file"  name="image"  onChange={(e)=> setImage(e.target.files[0])} style={{border: 'none', background: 'none'}} />
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="modal-footer mt-3"></div>
                                                            <div className="form-group float-right">
                                                                <button type="button" className="btn btn-sm btn-danger mr-2">Cancel</button>
                                                                <button className="btn btn-sm btn-primary" onClick={UpdateData} >Update</button>
                                                            </div>
                                                        </div>
                                                    </div>{/* .card-preview */}
                                                </div> {/* nk-block */}
                                            </div>{/* .components-preview */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* content @e */}
                            {/* Footer */}
                            <div className="nk-footer">
                                <Footer />
                            </div>
                            {/* footer @e */}
                        </div>
                        {/* wrap @e */}
                    </div>
                    {/* main @e */}
                </div>
                {/* nk-app-root */}
            </div>
        </div>
    )
}
