import React, {useState, useEffect} from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as MyConstants from '../../../../Constant/Config'
import axios from 'axios'
import swal from 'sweetalert'

export default function AddStores({closeModal, edit}) 
{
    const Outline = 
    {
        boxShadow: 'none',
        borderColor: '#9d72ff',
        backgroundColor: 'transparent'
    }

    const [ code, setCode ] = useState("");
    const [ name, setName ] = useState("");
    const [ phone, setPhone ] = useState("");
    const [ email, setEmail ] = useState("");
    const [ address, setAddress ] = useState("");
    const [ user_id, setUserId ] = useState("");
    const [ category_id, setCategoryId ] = useState("");
    const [image, setImage] = useState("");
    const [commission, setCommission] = useState("");

    // Get Users Data 
    const [Users, setUsers] = useState([]);
    // Get Categories Data 
    const [Categories, setCategories] = useState([]);

    useEffect(() => {
        // Users
        fetch(MyConstants.listUser,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((result) => 
        {
            result.json().then((response) => 
            {
                console.warn(response);
                if(response.status == true)
                {
                    setUsers(response.users);
                }
            })
        });

        // Categories
        fetch(MyConstants.ListCategories,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((result) => 
            {
                result.json().then((response) => 
                {
                    console.warn(response);
                    if(response.status == true)
                    {
                        setCategories(response.categories);
                    }
                })
            });
    }, [])

    // AddData
    const AddData = (e) => 
    {
        const formData =  new FormData();
		formData.append('name',name);
        formData.append('code',code);
        formData.append('email',email);
        formData.append('address',address);
        formData.append('phone',phone);
        formData.append('commission',commission);
        formData.append('user_id',user_id);
        formData.append('category_id',category_id);
        formData.append('phone',phone);
        formData.append('image',image);
		axios.post(MyConstants.addStores,formData)
		.then((result)=>{
			if(result.data.status == true){
                closeModal(true);
                edit();
                swal({
                    title: "Success!",
                    text: result.data.status.message,
                    icon: "success",
                    button: "Ok",
                });
            }
		})
    }

    
    return (
        <div>
            <div className="form-group" >
                <label className="form-label" htmlFor="code">Code</label>
                <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                <div className="form-control-wrap">
                    <input type="text" className="form-control" name="code" placeholder="Enter Code" style={Outline} onChange={ (e)=>setCode(e.target.value) } required />
                </div>
            </div>
            
            <div className="form-group" >
                <label className="form-label" htmlFor="name">Name</label>
                <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                <div className="form-control-wrap">
                    <input type="text" className="form-control" name="name" placeholder="Enter Store Name" style={Outline} onChange={ (e)=>setName(e.target.value) } required />
                </div>
            </div>
            
            <div className="form-group" >
                <label className="form-label" htmlFor="">Comission</label>
                <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                <input type="number" className="form-control" name="comission" placeholder="Enter Comission" style={Outline} onChange={ (e)=>setCommission(e.target.value) } required />
            </div>
            
            <div className="form-group" >
                <label className="form-label" htmlFor="">Users</label>
                <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                <select className='form-control custom-select' value={user_id} onChange={ (e)=>setUserId(e.target.value) }>
                <option>Select User</option>
                    { Users.map(item =>
                        <option value={item.id}>{item.name}</option>
                    )}
                </select>
            </div>

            <div className="form-group" >
                <label className="form-label" htmlFor="price_group">Categories</label>
                <div className="form-control-wrap">
                    <select className='form-control custom-select' value={category_id} onChange={ (e)=>setCategoryId(e.target.value) }>
                    <option>Select Category</option>
                    { Categories.map(item =>
                        <option value={item.id}>{item.name}</option>
                    )}
                    </select>
                </div>
            </div>
            
            <div className="form-group" >
                <label className="form-label" htmlFor="contact_no">Store Contact No</label>
                <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                <div className="form-control-wrap">
                    <input type="number" className="form-control" name="phone" placeholder="Enter Contact No" style={Outline} onChange={ (e)=>setPhone(e.target.value) } required />
                </div>
            </div>

            <div className="form-group" >
                <label className="form-label" htmlFor="email">Email Address</label>
                <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                <div className="form-control-wrap">
                    <input type="email" className="form-control" name="email" placeholder="Enter Email Address" style={Outline} onChange={ (e)=>setEmail(e.target.value) } required />
                </div>
            </div>
            
            <div className="form-group" >
                <label className="form-label" htmlFor="address">Address</label>
                <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                <div className="form-control-wrap">
                    <input type="text" className="form-control" name="address" placeholder="Enter Address" style={Outline} onChange={ (e)=>setAddress(e.target.value) } required />
                </div>
            </div>
            
            <div className="form-group" >
                <label >Image</label>
                <input type="file"  name="image" onChange={(e)=> setImage(e.target.files[0])}  style={{border: 'none', background: 'none'}} />
            </div>

            <div className="modal-footer bg-white"></div>
            <div className="form-group float-right">
                <button type="button" className="btn btn-sm btn-danger mr-2" onClick={closeModal}>Cancel</button>
                <button className="btn btn-sm btn-primary" style={{ backgroundColor: "#9d72ff", border: "#9d72ff", outline: "none", boxShadow: "none" }} onClick={AddData}>Add Store</button>
            </div>
        </div>
    )
}
