import React, { useState, useEffect } from 'react'
import Sidebar from '../../Sidebar'
// Header
import Header from '../../Header'
// Footer
import Footer from '../../Footer'
import {Button} from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom';
import * as MyConstants from '../../../../Constant/Config'
import swal from 'sweetalert'
import { Spinner } from 'react-bootstrap'


// Image
const Image = 
{
    height: '100px',
    width: '100px',
    borderRadius: '10px'
}
 
export default function ListProducts() 
{
    // Get ListProducts Data 
    const [ListProducts, setListProducts] = useState([]);
    const [EditData, setEditData] = useState([]);
  const[isLoading,setIsLoading]=useState(false);


    useEffect(() => {
        fetch(MyConstants.listProduct,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((result) => 
            {
                result.json().then((response) => 
                {
                    console.warn(response);
                    if(response.status == true)
                    {
                        setListProducts(response.products);
                        setIsLoading(true);
                    }
                })
            })
    }, []);
    console.warn(ListProducts);

    const edit = () => 
    {
        fetch(MyConstants.listProduct,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((result) => 
        {
            result.json().then((response) => 
            {
                console.warn(response);
                if(response.status == true)
                {
                    setListProducts(response.products);
                }
            })
        })
    }


    // Edit
    const History = useHistory();
    const NavigateTo = () => History.push('/add-product');
    const Navigate = () => History.push('/edit-product/'); 

    // Delete Data
    const DeleteData = (id) => 
    {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this imaginary file!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then((willDelete) => {
            if (willDelete) {
                fetch(MyConstants.deleteProduct + id,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then((result) => 
                {
                    result.json().then((response) => 
                    {
                        console.warn(response);
                        if(response.status == true)
                        {
                            swal("Your imaginary row has been deleted!", {
                                icon: "success",
                            });
                            edit();
                        }
                    })
                })
            } else {
              swal("Your imaginary row is safe!");
            }
        });
    }

    return (
        <div>
            <div className="nk-app-root">
                {/* main @s */}
                <div className="nk-main ">
                    {/* sidebar @s */}
                    <div className="nk-sidebar nk-sidebar-fixed is-light " data-content="sidebarMenu">
                        <Sidebar />
                    </div>
                    {/* Sidebar @e */}
                    {/* wrap @s */}
                    <div className="nk-wrap ">
                        {/* main header @s */}
                        <div className="nk-header nk-header-fixed is-light">
                            {/* Header */}
                            <Header />
                        </div>
                        {/* main header @e */}

                        {/* content @s */}
                        <div className="nk-content ">
                            <div className="container-fluid">
                                <div className="nk-content-inner">
                                    <div className="nk-content-body">
                                        <div class="components-preview">
                                            <div className="nk-block-head nk-block-head-sm card p-4" style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', outline: 'none', marginTop: '20px' }}>
                                                <div className="nk-block-between">
                                                    <div className="nk-block-head-content">
                                                        <h3 className="nk-block-title page-title" style={{ color: "#9d72ff" }}>Products</h3>
                                                    </div>{/* .nk-block-head-content */}
                                                    <div className="nk-block-head-content">
                                                        <Button className="btn btn-primary btn-sm d-none d-md-inline-flex" style={{ backgroundColor: "#9d72ff", border: "#9d72ff", outline: "none", boxShadow: "none" }} onClick={ NavigateTo } ><em className="icon ni ni-plus"/><span>Add Product</span></Button>
                                                    </div>{/* .nk-block-head-content */}
                                                </div>{/* .nk-block-between */}
                                            </div>{/* .nk-block-head */}
                                            <div className="nk-block nk-block-lg mt-5">
                                                <div className="card card-preview" style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', outline: 'none', marginTop: '20px' }}>
                                                    <div className="card-inner">
                                                        <table className="table table-bordered table-hover table-striped table-responsive-sm">
                                                            <thead>
                                                                <tr>
                                                                    <th>Sr#</th>
                                                                    <th>Image</th>
                                                                    <th>Code</th>
                                                                    <th>Name</th>
                                                                    <th>Brand</th>
                                                                    <th>Description</th>
                                                                    <th>Actions</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                            {isLoading ?
                                                                (
                                                                    ListProducts.map(item =>
                                                                    <tr>
                                                                        <td>{item.id}</td>
                                                                        <td><img src={MyConstants.ImageUrl+`${item.image}`} style={Image}  /></td>
                                                                        <td>{item.code}</td>
                                                                        <td>{item.name}</td>
                                                                        <td>{item.brdname}</td>
                                                                        <td>{item.detail}</td>
                                                                        <td>
                                                                            <Link to={"edit-product/" + item.id}  className="btn btn-primary d-none  btn-sm d-md-inline-flex" style={{ backgroundColor: "#9d72ff", border: "#9d72ff", outline: "none", boxShadow: "none" }}><em className="icon ni ni-edit" /><span>Edit</span></Link>
                                                                            <Button className="btn btn-danger btn-sm ml-2 d-md-inline-flex"  onClick={() => DeleteData(item.id)}><em className="icon ni ni-trash" /><span>Delete</span></Button>
                                                                        </td>
                                                                    </tr>
                                                                )):
                                                                (<Spinner style={{marginLeft:"150px"}} animation={'border'} style={{position:'absolute',top:'100%',left:'50%'}}/>)}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>{/* .card-preview */}
                                            </div> {/* nk-block */}

                                        </div>{/* .components-preview */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* content @e */}
                        {/* Footer */}
                        <div className="nk-footer">
                            <Footer />
                        </div>
                        {/* footer @e */}
                    </div>
                    {/* wrap @e */}
                </div>
                {/* main @e */}
            </div>
            {/* nk-app-root */}

        </div>
    )
}
