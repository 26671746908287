import React, {useState} from 'react'
import * as MyConstants from '../../../../Constant/Config' 

export default function EditBrands({ closeModal,id, name, code, description, edit }) 
{
    const Outline = 
    {
        boxShadow: 'none',
        borderColor: '#9d72ff',
        backgroundColor: 'transparent'
    }

    const [ EditBrands, setEditBrands ] = useState(
    {
        'id': id,
        name: name,
        code: code,
        description: description
    });
        
    const onInputEditValue = (e) =>
    {
        setEditBrands({...EditBrands, [e.target.name]: e.target.value})
    };

    const UpdateData = () =>
    {
        fetch(MyConstants.updateBrand,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(EditBrands)
        }).then((result) => 
        {
            
            result.json().then((response) => 
            {
                console.warn('hgghfhgf',response);
                if(response.status == true)
                {
                    closeModal(true);
                    edit();
                }
            })
        })
    }
    
    return (
        <div>
            <div className="form-group" >
                <label className="form-label" htmlFor="code">Code</label>
                <div className="form-control-wrap">
                    <input type="text" className="form-control" name="code" placeholder="Enter Code" defaultValue={code} style={Outline} onChange={onInputEditValue} required />
                </div>
            </div>
            
            <div className="form-group" >
                <label className="form-label" htmlFor="name">Name</label>
                <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                <div className="form-control-wrap">
                    <input type="text" className="form-control" name="name" placeholder="Enter Name" style={Outline} defaultValue={name} onChange={onInputEditValue} required />
                </div>
            </div>
            
            <div className="form-group" >
                <label className="form-label" htmlFor="description">Description</label>
                <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                <div className="form-control-wrap">
                    <input type="text" className="form-control" name="description" placeholder="Enter Description" style={Outline} onChange={onInputEditValue} defaultValue={description} required />
                </div>
            </div>

            <div className="modal-footer bg-white"></div>
            <div className="form-group float-right">
                <button type="button" className="btn btn-sm btn-danger mr-2" onClick={closeModal}>Cancel</button>
                <button className="btn btn-sm btn-primary" onClick={UpdateData} >Update</button>
            </div>
        </div>
    )
}
