import React, {useState, useEffect} from 'react'
import Sidebar from '../../Sidebar'
// Header
import Header from '../../Header'
// Footer
import Footer from '../../Footer'
import {Modal,Button} from 'react-bootstrap'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as MyConstants from '../../../../Constant/Config'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import swal from 'sweetalert'

export default function AddProducts() 
{
    // Get ListProducts Data 
    const [ListProducts, setListProducts] = useState([]);
    // Get Brands Data 
    const [Brands, setBrands] = useState([]);
    // Get Categories Data 
    const [Categories, setCategories] = useState([]);
    // Get SubCategories Data 
    const [SubCategories, setSubCategories] = useState([]);
    // Get Currency Data 
    const [Currency, setCurrency] = useState([]);
    // Get Units Data 
    const [Units, setUnits] = useState([]);
    // Get Stores Data 
    const [Stores, setStores] = useState([]);

    useEffect(() => {
        // Products
        fetch(MyConstants.listProduct,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((result) => 
        {
            result.json().then((response) => 
            {
                console.warn(response);
                if(response.status == true)
                {
                    setListProducts(response.products);
                }
            })
        });

        // Brands
        fetch(MyConstants.listBrand,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((result) => 
        {
            result.json().then((response) => 
            {
                console.warn(response);
                if(response.status == true)
                {
                    setBrands(response.brands);
                }
            })
        });

        // Categories
        fetch(MyConstants.ListCategories,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((result) => 
        {
            result.json().then((response) => 
            {
                console.warn(response);
                if(response.status == true)
                {
                    setCategories(response.categories);
                }
            })
        });

        // Sub Categories
        fetch(MyConstants.listSubCategories,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((result) => 
        {
            result.json().then((response) => 
            {
                console.warn(response);
                if(response.status == true)
                {
                    setSubCategories(response.sub_categories);
                }
            })
        });

        // Currency
        fetch(MyConstants.listCurrency,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((result) => 
        {
            result.json().then((response) => 
            {
                console.warn(response);
                if(response.status == true)
                {
                    setCurrency(response.currencies);
                }
            })
        });

        // Units
        fetch(MyConstants.listUnit,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((result) => 
        {
            result.json().then((response) => 
            {
                console.warn(response);
                if(response.status == true)
                {
                    setUnits(response.units);
                }
            })
        });

        
        // Stores
        fetch(MyConstants.ListStores,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((result) => 
        {
            result.json().then((response) => 
            {
                console.warn(response);
                if(response.status == true)
                {
                    setStores(response.stores);
                }
            })
        });
    },[]);

    const [ alert_quantity, setAlertQauntity ] = useState("");
    const [ detail, setDetail ] = useState("");
    const [ unit, setUnit ] = useState("");
    const [ type, setType ] = useState("");
    const [ sub_category, setSubCategory ] = useState("");
    const [ quantity, setQuantity ] = useState("");
    const [ name, setName ] = useState("");
    const [ brand, setBrand ] = useState("");
    const [ parent_category, setParentCategory ] = useState("");
    const [ cost, setCost ] = useState("");
    const [ currency, setProductCurrency ] = useState("");
    const [ price, setPrice ] = useState("");
    const [ store_id, setStoreId ] = useState("");
    const [ code, setCode ] = useState("");
    const [image, setImage] = useState("");

    const History = useHistory();
    const Navigate = () => History.push('/list-products');

    // AddData
    const AddData = (e) => 
    {
        const formData =  new FormData();
		formData.append('alert_quantity',alert_quantity);
		formData.append('detail',detail);
		formData.append('unit',unit);
		formData.append('name',name);
		formData.append('quantity',quantity);
		formData.append('sub_category',sub_category);
		formData.append('type',type);
		formData.append('price',price);
		formData.append('brand',brand);
		formData.append('cost',cost);
        formData.append('code',code);
        formData.append('store_id',store_id);
        formData.append('currency',currency);
        formData.append('image',image);
        formData.append('parent_category',parent_category);
		axios.post(MyConstants.addProduct,formData)
		.then((result)=>{
			if(result.data.status == true){
                console.log(result.data.message);
                console.log(result.data);
                History.push('/list-products');
                swal({
                    title: "Success!",
                    text: "New product has been addedd successfully!",
                    icon: "success",
                    button: "Ok",
                });
            }
		})
    }

    return (
        <div>
            <div>
                <div className="nk-app-root">
                    {/* main @s */}
                    <div className="nk-main ">
                        {/* sidebar @s */}
                        <div className="nk-sidebar nk-sidebar-fixed is-light " data-content="sidebarMenu">
                            <Sidebar />
                        </div>
                        {/* Sidebar @e */}
                        {/* wrap @s */}
                        <div className="nk-wrap ">
                            {/* main header @s */}
                            <div className="nk-header nk-header-fixed is-light">
                                {/* Header */}
                                <Header />
                            </div>
                            {/* main header @e */}

                            {/* content @s */}
                            <div className="nk-content ">
                                <div className="container-fluid">
                                    <div className="nk-content-inner">
                                        <div className="nk-content-body">
                                            <div class="components-preview">
                                                <div className="nk-block-head nk-block-head-sm card p-4" style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', outline: 'none', marginTop: '20px' }}>
                                                    <div className="nk-block-between">
                                                        <div className="nk-block-head-content">
                                                            <h3 className="nk-block-title page-title" style={{ color: "#9d72ff" }}>Add Products</h3>
                                                        </div>{/* .nk-block-head-content */}
                                                        <div className="nk-block-head-content">
                                                            <Button className="btn btn-primary btn-sm d-none d-md-inline-flex" style={{ backgroundColor: "#9d72ff", border: "#9d72ff", outline: "none", boxShadow: "none" }}  onClick={Navigate}><em className="icon ni ni-plus" /><span>Products List</span></Button>
                                                        </div>{/* .nk-block-head-content */}
                                                    </div>{/* .nk-block-between */}
                                                </div>{/* .nk-block-head */}
                                                <div className="nk-block nk-block-lg mt-5">
                                                    <div className="card card-preview" style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', outline: 'none', marginTop: '20px' }}>
                                                        <div className="card-inner">
                                                            <div className='row'>
                                                                <div className='col-6'>
                                                                    <label className="form-label" htmlFor="">Name</label>
                                                                    <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                                                                    <input type="text" className="form-control" name="name" placeholder="Enter Product Name" onChange={ (e)=>setName(e.target.value) }  required />
                                                                </div>
                                                                <div className='col-6'>
                                                                    <label className="form-label" htmlFor="">Type</label>
                                                                    <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                                                                    <select className='form-control custom-select' value={type} onChange={ (e)=>setType(e.target.value) }>
                                                                    <option>Select Product Type</option>
                                                                    { ListProducts.map(item =>
                                                                        <option value={item.id}>{item.type}</option>
                                                                    )}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className='row mt-3'>
                                                                <div className='col-6'>
                                                                    <label className="form-label" htmlFor="">Code</label>
                                                                    <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                                                                    <input type="text" className="form-control" name="code" placeholder="Enter Product Code"  onChange={ (e)=>setCode(e.target.value) } required />
                                                                </div>
                                                                <div className='col-6'>
                                                                    <label className="form-label" htmlFor="">Brand</label>
                                                                    <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                                                                    <select className='form-control custom-select' value={brand} onChange={ (e)=>setBrand(e.target.value) }>
                                                                    <option>Select Brand</option>
                                                                    { Brands.map(item =>
                                                                        <option value={item.id}>{item.name}</option>
                                                                    )}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className='row mt-3'>
                                                                <div className='col-6'>
                                                                    <label className="form-label" htmlFor="">Category</label>
                                                                    <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                                                                    <select className='form-control custom-select'  value={parent_category} onChange={ (e)=>setParentCategory(e.target.value) }>
                                                                    <option>Select Category</option>
                                                                    { Categories.map(item =>
                                                                        <option value={item.id}>{item.name}</option>
                                                                    )}
                                                                    </select>
                                                                </div>
                                                                <div className='col-6'>
                                                                    <label className="form-label" htmlFor="">Sub Category</label>
                                                                    <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                                                                    <select className='form-control custom-select' value={sub_category}  onChange={ (e)=>setSubCategory(e.target.value) }>
                                                                    <option>Select Sub Category</option>
                                                                    { SubCategories.map(item =>
                                                                        <option value={item.id}>{item.name}</option>
                                                                    )}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className='row mt-3'>
                                                                <div className='col-6'>
                                                                    <label className="form-label" htmlFor="">Currency</label>
                                                                    <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                                                                    <select className='form-control custom-select' value={currency} onChange={ (e)=>setProductCurrency(e.target.value) }>
                                                                    <option>Select Currency</option>
                                                                    { Currency.map(item =>
                                                                        <option value={item.id}>{item.symbol}</option>
                                                                    )}
                                                                    </select>
                                                                </div>
                                                                <div className='col-6'>
                                                                    <label className="form-label" htmlFor="">Units</label>
                                                                    <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                                                                    <select className='form-control custom-select' value={unit} onChange={ (e)=>setUnit(e.target.value) }>
                                                                    <option>Select Units</option>
                                                                    { Units.map(item =>
                                                                        <option value={item.id}>{item.name}</option>
                                                                    )}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className='row mt-3'>
                                                                <div className='col-6'>
                                                                    <label className="form-label" htmlFor="">Price</label>
                                                                    <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                                                                    <input type="number" className="form-control" name="price" placeholder="Enter Product Price" onChange={ (e)=>setPrice(e.target.value) }  required />
                                                                </div>
                                                                <div className='col-6'>
                                                                    <label className="form-label" htmlFor="">Alert Quantity</label>
                                                                    <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                                                                    <input type="number" className="form-control" name="alert_quantity" placeholder="Enter Alert Quantity" onChange={ (e)=>setAlertQauntity(e.target.value) }  required />
                                                                </div>
                                                            </div>
                                                            <div className='row mt-3'>
                                                                <div className='col-6'>
                                                                    <label className="form-label" htmlFor="">Quantity</label>
                                                                    <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                                                                    <input type="number" className="form-control" name="price" placeholder="Enter Product Quantity"  onChange={ (e)=>setQuantity(e.target.value) } required />
                                                                </div>
                                                                <div className='col-6'>
                                                                    <label className="form-label" htmlFor="">Stores</label>
                                                                    <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                                                                    <select className='form-control custom-select' value={store_id} onChange={ (e)=>setStoreId(e.target.value) }>
                                                                    <option>Select Store</option>
                                                                    { Stores.map(item =>
                                                                        <option value={item.id}>{item.name}</option>
                                                                    )}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className='row mt-3'>
                                                                <div className='col-6'>
                                                                    <label className="form-label" htmlFor="">Cost</label>
                                                                    <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                                                                    <input type="number" className="form-control" name="cost" placeholder="Enter Cost"  onChange={ (e)=>setCost(e.target.value) } required />
                                                                </div>
                                                                <div className='col-6'>
                                                                    <label className="form-label" htmlFor="">Details</label>
                                                                    <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                                                                    <input type="text" className="form-control" name="details" placeholder="Enter Details" onChange={ (e)=>setDetail(e.target.value) }  required />
                                                                </div>
                                                            </div>
                                                            <div className='row mt-3'>
                                                                <div className='col-6'>
                                                                    <label >Image</label>
                                                                    <input type="file"  name="image" onChange={(e)=>setImage(e.target.files[0])} style={{border: 'none', background: 'none'}} />
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="modal-footer mt-3"></div>
                                                            <div className="form-group float-right">
                                                                <button type="button" className="btn btn-sm btn-danger mr-2">Cancel</button>
                                                                <button className="btn btn-sm btn-primary" style={{ backgroundColor: "#9d72ff", border: "#9d72ff", outline: "none", boxShadow: "none" }} onClick={AddData}>Add Product</button>
                                                            </div>
                                                        </div>
                                                    </div>{/* .card-preview */}
                                                </div> {/* nk-block */}
                                            </div>{/* .components-preview */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* content @e */}
                            {/* Footer */}
                            <div className="nk-footer">
                                <Footer />
                            </div>
                            {/* footer @e */}
                        </div>
                        {/* wrap @e */}
                    </div>
                    {/* main @e */}
                </div>
                {/* nk-app-root */}
            </div>
        </div>
    )
}
