import React, {useState, useEffect} from 'react'
import Sidebar from '../../Sidebar'
// Header
import Header from '../../Header'
// Footer
import Footer from '../../Footer'
import {Modal,Button} from 'react-bootstrap'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as MyConstants from '../../../../Constant/Config'
import { useHistory } from 'react-router-dom'


export default function AddUsers() 
{
    const History = useHistory();
    const NavigateTo = () => History.push('/list-users');
    const NavigateToBack = () => History.goBack();

    const [ first_name, setFirstName ] = useState("");
    const [ last_name, setLastName ] = useState("");
    const [ company, setCompany ] = useState("");
    const [ email, setEmail ] = useState("");
    const [ phone, setPhone ] = useState("");

    // AddUser
    const AddUser = (e) => 
    {
        let data = {"first_name": first_name, "last_name": last_name, "company": company, "email": email, "phone": phone};
        fetch(MyConstants.addUser,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then((result) => 
        {
            result.json().then((response) => 
            {
                if(response.status == true)
                {
                    History.push('/list-users');
                }
            })
        })
    }

    return (
        <div>
            <div>
                <div className="nk-app-root">
                    {/* main @s */}
                    <div className="nk-main ">
                        {/* sidebar @s */}
                        <div className="nk-sidebar nk-sidebar-fixed is-light " data-content="sidebarMenu">
                            <Sidebar />
                        </div>
                        {/* Sidebar @e */}
                        {/* wrap @s */}
                        <div className="nk-wrap ">
                            {/* main header @s */}
                            <div className="nk-header nk-header-fixed is-light">
                                {/* Header */}
                                <Header />
                            </div>
                            {/* main header @e */}

                            {/* content @s */}
                            <div className="nk-content ">
                                <div className="container-fluid">
                                    <div className="nk-content-inner">
                                        <div className="nk-content-body">
                                            <div class="components-preview">
                                                <div className="nk-block-head nk-block-head-sm card p-4" style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', outline: 'none', marginTop: '20px' }}>
                                                    <div className="nk-block-between">
                                                        <div className="nk-block-head-content">
                                                            <h3 className="nk-block-title page-title" style={{ color: "#9d72ff" }}>Add Users</h3>
                                                        </div>{/* .nk-block-head-content */}
                                                        <div className="nk-block-head-content">
                                                            <Button className="btn btn-primary btn-sm d-none d-md-inline-flex" style={{ backgroundColor: "#9d72ff", border: "#9d72ff", outline: "none", boxShadow: "none" }}  onClick={NavigateTo}><em className="icon ni ni-plus" /><span>Users List</span></Button>
                                                        </div>{/* .nk-block-head-content */}
                                                    </div>{/* .nk-block-between */}
                                                </div>{/* .nk-block-head */}
                                                <div className="nk-block nk-block-lg mt-5">
                                                    <div className="card card-preview" style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', outline: 'none', marginTop: '20px' }}>
                                                        <div className='card-inner'>
                                                            <div className='row'>
                                                                <div className='col-6'>
                                                                    <label className="form-label" >First Name</label>
                                                                    <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                                                                    <input type="text" className="form-control" name="first_name" placeholder="Enter First Name" onChange={ (e)=>setFirstName(e.target.value) }  required />
                                                                </div>
                                                                <div className='col-6'>
                                                                    <label className="form-label" >Last Name</label>
                                                                    <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                                                                    <input type="text" className="form-control" name="last_name" placeholder="Enter Last  Name"  onChange={ (e)=>setLastName(e.target.value) } required />
                                                                </div>
                                                            </div>
                                                            <div className='row mt-3'>
                                                                <div className='col-6'>
                                                                    <label className="form-label" >Email Address</label>
                                                                    <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                                                                    <input type="email" className="form-control" name="email" placeholder="Enter Email Address" onChange={ (e)=>setEmail(e.target.value) }  required />
                                                                </div>
                                                                <div className='col-6'>
                                                                    <label className="form-label" >Phone</label>
                                                                    <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                                                                    <input type="number" className="form-control" name="phone" placeholder="Enter Phone Number"  onChange={ (e)=>setPhone(e.target.value) }  required />
                                                                </div>
                                                            </div>
                                                            <div className='row mt-3'>
                                                                <div className='col-6'>
                                                                    <label className="form-label" >Company</label>
                                                                    <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                                                                    <input type="text" className="form-control" name="company" placeholder="Enter Company Name"  onChange={ (e)=>setCompany(e.target.value) } required />
                                                                </div>
                                                                <div className='col-6'>
                                                                    <label className="form-label" >Gender</label>
                                                                    <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                                                                    <select className='form-control custom-select'>
                                                                        <option>Male</option>
                                                                        <option>Female</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="modal-footer mt-3"></div>
                                                            <div className="form-group float-right">
                                                                <button type="button" className="btn btn-sm btn-danger mr-2" onClick={NavigateToBack}>Cancel</button>
                                                                <button className="btn btn-sm btn-primary" style={{ backgroundColor: "#9d72ff", border: "#9d72ff", outline: "none", boxShadow: "none" }} onClick={AddUser}>Add User</button>
                                                            </div>
                                                        </div>
                                                    </div>{/* .card-preview */}
                                                </div> {/* nk-block */}
                                            </div>{/* .components-preview */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* content @e */}
                            {/* Footer */}
                            <div className="nk-footer">
                                <Footer />
                            </div>
                            {/* footer @e */}
                        </div>
                        {/* wrap @e */}
                    </div>
                    {/* main @e */}
                </div>
                {/* nk-app-root */}
            </div>
        </div>
    )
}
