import React, { useState, useEffect } from 'react'
import * as MyConstants from '../../../../Constant/Config' 
import axios from 'axios'

export default function EditSubCategories({ closeModal, id, code, name, ctgname, parent_category, description, edit  }) 
{
    const Outline = 
    {
        boxShadow: 'none',
        borderColor: '#9d72ff',
        backgroundColor: 'transparent'
    }

    const [image, setImage] = useState("");
    const [ EditSubCategories, setEditSubCategories ] = useState(
    {
        id: id,
        code: code,
        name: name,
        parent_category: parent_category,
        description: description
    });
    
    const onInputEditValue = (e) =>
    {
        
        setEditSubCategories({...EditSubCategories, [e.target.name]: e.target.value});
    };

    console.log(parent_category);
    const UpdateData = () =>
    {
        console.log(EditSubCategories);
        const formData =  new FormData();
		formData.append('id',EditSubCategories.id);
		formData.append('name',EditSubCategories.name);
        formData.append('code',EditSubCategories.code); 
        formData.append('description',EditSubCategories.description); 
        formData.append('image',image); 
        formData.append('parent_category',EditSubCategories.parent_category); 
		axios.post(MyConstants.updateSubCategory,formData)
		.then((result)=>{
			if(result.data.status == true){
                console.log(result.data.message);
                closeModal(true);
                edit();
            }
		})
    }

    // Get Categories Data 
    const [Categories, setCategories] = useState([]);

    useEffect(() => {
        fetch(MyConstants.ListCategories,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((result) => 
        {
            result.json().then((response) => 
            {
                console.warn(response);
                if(response.status == true)
                {
                    setCategories(response.categories);
                }
            })
        })
    }, []);


    return (
        <div>
            <div className="form-group" >
                <label className="form-label" htmlFor="code">Category Code</label>
                <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                <div className="form-control-wrap">
                    <input type="text" className="form-control" name="code" placeholder="Enter Category Code" defaultValue={code} style={Outline} onChange={onInputEditValue} required />
                </div>
            </div>
            
            <div className="form-group" >
                <label className="form-label" htmlFor="name">Category Name</label>
                <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                <div className="form-control-wrap">
                    <input type="text" className="form-control" name="name" placeholder="Enter Category Name" defaultValue={name} style={Outline} onChange={onInputEditValue} required />
                </div>
            </div>
            
            <div className="form-group" >
                <label className="form-label" htmlFor="parent_category">Parent Category</label>
                <div className="form-control-wrap">
                    <select className='form-control custom-select' name='parent_category'>
                    { Categories.map(item =>
                        (EditSubCategories.parent_category == item.id ) ? (<option  value={item.id} selected>{item.name}</option>) : (<option  value={item.id}>{item.name}</option>)
                    )}
                    </select>
                </div>
            </div>
            
            <div className="form-group" >
                <label className="form-label" htmlFor="description">Description</label>
                <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                <div className="form-control-wrap">
                    <input type="text" className="form-control" name="description" placeholder="Enter Description" defaultValue={description} style={Outline} onChange={onInputEditValue} required />
                </div>
            </div>
            
            <div className="form-group" >
                <label >Image</label>
                <input type="file"  name="image" onChange={(e)=> setImage(e.target.files[0])}  style={{border: 'none', background: 'none'}} />
            </div>

            <div className="modal-footer bg-white"></div>
            <div className="form-group float-right">
                <button type="button" className="btn btn-sm btn-danger mr-2" onClick={closeModal}>Cancel</button>
                <button className="btn btn-sm btn-primary" onClick={UpdateData} >Update</button>
            </div>
        </div>
    )
}
 