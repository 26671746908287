import React, { useState, useEffect } from 'react'
import Sidebar from '../../Sidebar'
// Header
import Header from '../../Header'
// Footer
import Footer from '../../Footer'
import {Button} from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom';
import * as MyConstants from '../../../../Constant/Config'
// Image
const Image = 
{
    height: '100px',
    width: '100px',
    borderRadius: '10px'
}

// StatusStyle
const StatusStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '44px',
    width: '100%',
    borderRadius: '10px',   
    backgroundColor: '#dff7fb',
    color: '#13c9f2',
    fontWeight: '600'
}
 
export default function ListOrders() 
{

    const [value,setValue]=useState('');



    // Get ListOrders Data 
    const [ListOrders, setListOrders] = useState([]);

    useEffect(() => {
        fetch(MyConstants.listOrders,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((result) => 
            {
                result.json().then((response) => 
                {
                    console.warn(response);
                    if(response.status == true)
                    {
                        setListOrders(response.orders);
                    }
                })
            })
    }, []);
    console.warn(ListOrders);


    // Edit
    const History = useHistory();
    const NavigateTo = () => History.push('/add-orders');

    return (
        <div>
            <div className="nk-app-root">
                {/* main @s */}
                <div className="nk-main ">
                    {/* sidebar @s */}
                    <div className="nk-sidebar nk-sidebar-fixed is-light " data-content="sidebarMenu">
                        <Sidebar />
                    </div>
                    {/* Sidebar @e */}
                    {/* wrap @s */}
                    <div className="nk-wrap ">
                        {/* main header @s */}
                        <div className="nk-header nk-header-fixed is-light">
                            {/* Header */}
                            <Header />
                        </div>
                        {/* main header @e */}

                        {/* content @s */}
                        <div className="nk-content ">
                            <div className="container-fluid">
                                <div className="nk-content-inner">
                                    <div className="nk-content-body">
                                        <div class="components-preview">
                                            <div className="nk-block-head nk-block-head-sm card p-4" style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', outline: 'none', marginTop: '20px' }}>
                                                <div className="nk-block-between">
                                                    <div className="nk-block-head-content">
                                                        <h3 className="nk-block-title page-title" style={{ color: "#9d72ff" }}>Orders</h3>
                                                    </div>{/* .nk-block-head-content */}
                                                    <div className="nk-block-head-content">
                                                        <Button className="btn btn-primary btn-sm d-none d-md-inline-flex" style={{ backgroundColor: "#9d72ff", border: "#9d72ff", outline: "none", boxShadow: "none" }} onClick={ NavigateTo } ><em className="icon ni ni-plus"/><span>Add Orders</span></Button>
                                                    </div>{/* .nk-block-head-content */}
                                                </div>{/* .nk-block-between */}
                                            </div>{/* .nk-block-head */}
                                            <div className="nk-block nk-block-lg mt-5">
                                                <div className="card card-preview" style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', outline: 'none', marginTop: '20px' }}>
                                                    <div className="card-inner">
                                                        <table className="table table-bordered table-hover table-striped table-responsive-sm">
                                                            <thead>
                                                                <tr>
                                                                    <th>Sr#</th>
                                                                    <th>Name</th>
                                                                    <th>Conatct No</th>
                                                                    <th>Items</th>
                                                                    <th>Quantity</th>
                                                                    <th>Total</th>
                                                                    <th>Order Status</th>
                                                                    <th>Payment Status</th>
                                                                    <th>Actions</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {ListOrders.map(item =>
                                                                    <tr>
                                                                        <td>1</td>
                                                                        <td>{item.first_name} {item.last_name}</td>
                                                                        <td>{item.phone}</td>
                                                                        <td>{item.items}</td>
                                                                        <td>{item.quantities}</td>
                                                                        <td>{item.total}</td>
                                                                        <td>
                                                                            {value ? (<span style={StatusStyle}>{value}</span>) : (<span style={StatusStyle}>{item.order_status}</span>)}
                                                                        </td>
                                                                        <td>
                                                                            {value ? (<span style={StatusStyle}>{value}</span>) : (<span style={StatusStyle}>{item.payment_status}</span>)}
                                                                        </td>
                                                                        <td>
                                                                            <Link to={"/orders-detail/" + item.order_id} className="btn btn-success d-none  btn-sm d-md-inline-flex mr-2" style={{ outline: "none", boxShadow: "none" }}><em className="icon ni ni-eye" /><span>Show Order</span></Link>
                                                                            {/*<select className='custom-select w-50'  name="status" onChange={(e) => setValue(e.target.value)}>
                                                                                <option value="Status">Select Status</option>
                                                                                <option value="Pending">Pending</option>
                                                                                <option value="Confirmed">Confirmed</option>
                                                                                <option value="Return">Return</option>
                                                                </select>*/}
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                                
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>{/* .card-preview */}
                                            </div> {/* nk-block */}

                                        </div>{/* .components-preview */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* content @e */}
                        {/* Footer */}
                        <div className="nk-footer">
                            <Footer />
                        </div>
                        {/* footer @e */}
                    </div>
                    {/* wrap @e */}
                </div>
                {/* main @e */}
            </div>
            {/* nk-app-root */}

        </div>
    )
}
