import React, { useState } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as MyConstants from '../../../../Constant/Config' 
import axios from 'axios'
import swal from 'sweetalert'

export default function EditCategories({ closeModal, id, code, name, description, edit  }) 
{
    const Outline = 
    {
        boxShadow: 'none',
        borderColor: '#9d72ff',
        backgroundColor: 'transparent'
    }

    const [image, setImage] = useState("");

    const [ EditCategories, setEditCategories ] = useState(
    {
        'id': id,
        code: code,
        name: name,
        description: description
    });
    
    const onInputEditValue = (e) =>
    {
        setEditCategories({...EditCategories, [e.target.name]: e.target.value})
    };

    const UpdateData = () =>
    {
        console.log(image);
        const formData =  new FormData();
		formData.append('id',EditCategories.id);
		formData.append('name',EditCategories.name);
        formData.append('code',EditCategories.code);
        formData.append('description',EditCategories.description);
        formData.append('image',image);
		axios.post(MyConstants.UpdateCategories,formData)
		.then((result)=>{
			if(result.status == true){
                setEditCategories(result.categories);
                closeModal(true);
                edit();
                swal({
                    title: "Success!",
                    text: "Category data is updated successfully!",
                    icon: "success",
                    button: "Ok",
                });
            }
		})
    }

    return (
        <div>
                <div className="form-group" >
                    <label className="form-label" htmlFor="code">Category Code</label>
                    <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                    <div className="form-control-wrap">
                        <input type="text" className="form-control" name="code" placeholder="Enter Category Code" defaultValue={ code } onChange={onInputEditValue} style={Outline} required />
                    </div>
                </div>
                
                <div className="form-group" >
                    <label className="form-label" htmlFor="name">Category Name</label>
                    <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                    <div className="form-control-wrap">
                        <input type="text" className="form-control" name="name" placeholder="Enter Category Name" defaultValue={ name } onChange={onInputEditValue} style={Outline} required />
                    </div>
                </div>
                
                <div className="form-group" >
                    <label className="form-label" htmlFor="description">Description</label>
                    <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                    <div className="form-control-wrap">
                        <input type="text" className="form-control" name="description" placeholder="Enter Description" defaultValue={ description } onChange={onInputEditValue} style={Outline} required />
                    </div>
                </div>
                
                <div className="form-group" >
                    <label className="form-label" htmlFor="image">Category Image</label>
                    <input type="file" onChange={(e)=>setImage(e.target.files[0])}  name="image" style={{border: 'none', background: 'none'}} />
                </div>

                <div className="modal-footer bg-white"></div>
                <div className="form-group float-right">
                    <button type="button" className="btn btn-sm btn-danger mr-2" onClick={closeModal}>Cancel</button>
                    <button className="btn btn-sm btn-primary" onClick={UpdateData} >Update</button>
                </div>
        </div>
    )
}
 