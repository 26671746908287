// URL
const URL = "http://e-mandiwebapp.xpertsgroup.net/public/api/";

// ImageUrl
export const ImageUrl = "http://e-mandiwebapp.xpertsgroup.net/public/images/";

// Categories
export const ListCategories = URL + "listCategories"; 
export const addCategory = URL + "addCategory"; 
export const UpdateCategories = URL + "updateCategory"; 
export const DeleteCategory = URL + "deleteCategory/"; 

// Sub Categories
export const listSubCategories = URL + "listSubCategories"; 
export const addSubCategory = URL + "addSubCategory"; 
export const updateSubCategory = URL + "updateSubCategory"; 
export const deleteSubCategory = URL + "deleteSubCategory/"; 

// Brands
export const listBrand = URL + "listBrand"; 
export const addBrand = URL + "addBrand"; 
export const updateBrand = URL + "updateBrand"; 
export const deleteBrand = URL + "deleteBrand/"; 

// Units
export const listUnit = URL + "listUnit"; 
export const addUnit = URL + "addUnit"; 
export const updateUnit = URL + "updateUnit"; 
export const deleteUnit = URL + "deleteUnit/"; 

// Variants
export const listVariants = URL + ""; 
export const addVariants = URL + ""; 
export const updateVariants = URL + ""; 
export const deleteVariants = URL + ""; 

// General Settings
export const listGeneral = URL + "listGeneral"; 
export const addGeneral = URL + ""; 
export const updateGeneral = URL + ""; 
export const deleteGeneral = URL + ""; 

// Currency
export const listCurrency = URL + "listCurrency"; 
export const addCurrency = URL + "addCurrency"; 
export const updateCurrency = URL + "updateCurrency"; 
export const deleteCurrency = URL + "deleteCurrency/"; 

// Text Rates
export const listText = URL + ""; 
export const addText = URL + ""; 
export const updateText = URL + ""; 
export const deleteText = URL + ""; 

// Stores
export const ListStores = URL + "listStore"; 
export const addStores = URL + "addStore"; 
export const updateStores = URL + "updateStore"; 
export const deleteStores = URL + "deleteStore/"; 

// Products
export const listProduct = URL + "listProduct"; 
export const addProduct = URL + "addProduct"; 
export const updateProduct = URL + "updateProduct"; 
export const deleteProduct = URL + "deleteProduct/"; 
export const editProduct = URL + "editProduct/"; 

// TaggedProducts
export const listTaggedProducts = URL + "listTagProduct"; 
export const addTaggedProduct = URL + "addTagProduct"; 
export const updateTaggedProduct = URL + "updateTagProduct"; 
export const deleteTaggedProduct = URL + "deleteTagProduct/";

// Purchases
export const listPurchase = URL + ""; 
export const AddPurchase = URL + ""; 
export const updatePurchase = URL + ""; 
export const deletePurchase = URL + ""; 

// Expenses
export const listExpense = URL + ""; 
export const addExpense = URL + ""; 
export const updateExpense = URL + ""; 
export const deleteExpense = URL + ""; 

// Users
export const listUser = URL + "listUsers"; 
export const addUser = URL + ""; 
export const updateUser = URL + ""; 
export const deleteUser = URL + ""; 

// Customers
export const listCustomer = URL + ""; 
export const addCustomer = URL + ""; 
export const updateCustomer = URL + ""; 
export const deleteCustomer = URL + ""; 

// Suppliers
export const listSupplier = URL + ""; 
export const addSupplier = URL + ""; 
export const updateSupplier = URL + ""; 
export const deleteSupplier = URL + ""; 

// Sales
export const listSale = URL + ""; 
export const addSale = URL + ""; 
export const updateSale = URL + ""; 
export const deleteSale = URL + ""; 

// Accounts
export const ListAccounts = URL + "listAccounts";
export const addAccount = URL + "addAccount";
// export const editAccount = URL + "";
export const updateAccount = URL + "updateAccount";
export const deleteAccount = URL + "deleteAccount/";

// FrontEndHomeData
export const FrontEndHomeData = URL + "FrontEndHomeData";
export const FrontEndProductDetail = URL + "FrontEndProductDetail/";

export const listOrders = URL + "listOrders";
export const showOrderDetail = URL + "showOrderDetail/";

export const categoryProducts = URL + "categoryProducts/";

//auth Apis
export const REGISTER = URL + "userSignup"; 
export const LOGIN = URL + "userLogin";
export const submitOrder = URL + "submitOrder";

export const updatePaymentStatus = URL + "updatePaymentStatus";

export const listSellers = URL + "listSellers";

export const listCountries = URL + "listCountries";
export const listStateProvince = URL + "listStateProvince";
export const listCity = URL + "listCity";




