import React from 'react'
import { Link } from 'react-router-dom'
// Dashboard Css Files 
import '../Dahboard/assets/css/dashlite.css'
import '../Dahboard/assets/css/theme.css'
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import {AiOutlineSetting} from "react-icons/ai"
import {BsFillCartCheckFill} from "react-icons/bs"
import {FaUsers} from "react-icons/fa"
import {FcSalesPerformance} from "react-icons/fc"
import {BiPurchaseTagAlt} from "react-icons/bi"
import {CgShoppingBag} from "react-icons/cg"
import {MdDashboardCustomize,MdManageAccounts} from "react-icons/md"

function Sidebar() 
{

    // Logout
    function Logout()
    {
        sessionStorage.clear();
        // Navigate("/login");
    }

    return (
        <>
            <Link to='/users'><h2 style={{color: '#fff', background: '#1d1d1d', padding: '20px 35px 8px 20px', borderBottom: '1px solid #e5e9f2'}}>E-Mandi</h2></Link>
            <ProSidebar style={{width: '100%'}}>
                {/* Settings */}
                <Menu iconShape="circle" >
                    <SubMenu title="Settings" icon={<AiOutlineSetting/>}>
                        <MenuItem>Categories<Link to="/categories" /></MenuItem>
                        <MenuItem>Sub Categories<Link to="/sub-categories" /></MenuItem>
                        <MenuItem>Brands<Link to="/brands" /></MenuItem>
                        <MenuItem>Units<Link to="/units" /></MenuItem>
                        <MenuItem>Variants<Link to="/variants" /></MenuItem>
                        <MenuItem>General Settings<Link to="/general-settings" /></MenuItem>
                        <MenuItem>Currency<Link to="/currency" /></MenuItem>
                        <MenuItem>Text Rates<Link to="/text-rates" /></MenuItem>
                        <MenuItem>Stores<Link to="/stores" /></MenuItem>
                    </SubMenu>
                </Menu>
                {/* products */}
                <Menu iconShape="circle" >
                    <SubMenu title="Products" icon={<BsFillCartCheckFill/>}>
                        <MenuItem>List Products<Link to="/list-products" /></MenuItem>
                        <MenuItem>Add Products<Link to="/add-product" /></MenuItem>
                        <MenuItem>Tagged Products<Link to="/tagg-product" /></MenuItem>
                    </SubMenu>
                </Menu>
                {/* Sales */}
                <Menu iconShape="circle" >
                    <SubMenu title="Sales" icon={<FcSalesPerformance/>}>
                        <MenuItem>List Sales<Link to="/list-sales" /></MenuItem>
                        <MenuItem>Add Sales<Link to="/add-sales" /></MenuItem>
                    </SubMenu>
                </Menu>
                {/* Purchases */}
                <Menu iconShape="circle" >
                    <SubMenu title="Purchases" icon={<BiPurchaseTagAlt/>}>
                        <MenuItem>List Purchases<Link to="/list-purchase" /></MenuItem>
                        <MenuItem>Add Purchases<Link to="/add-purchase" /></MenuItem>
                        <MenuItem>List Expenses<Link to="/list-expense" /></MenuItem>
                        <MenuItem>Add Expenses<Link to="/add-expense" /></MenuItem>
                    </SubMenu>
                </Menu>
                {/* People */}
                <Menu iconShape="circle" >
                    <SubMenu title="People" icon={<FaUsers/>}>
                        <MenuItem>List Users<Link to="/list-users" /></MenuItem>
                        <MenuItem>Add Users<Link to="/add-users" /></MenuItem>
                        <MenuItem>List Customers<Link to="/list-customers" /></MenuItem>
                        <MenuItem>Add Customers<Link to="/add-customers" /></MenuItem>
                        <MenuItem>List Suppliers<Link to="/list-suppliers" /></MenuItem>
                        <MenuItem>Add Suppliers<Link to="/add-suppliers" /></MenuItem>
                    </SubMenu>
                </Menu>

                {/* Orders */}
                <Menu iconShape="circle" >
                    <SubMenu title="Orders" icon={<CgShoppingBag/>}>
                        <MenuItem>List Orders<Link to="/list-orders" /></MenuItem>
                        <MenuItem>Add Orders<Link to="/add-orders" /></MenuItem>
                    </SubMenu>
                </Menu>
                {/* Accounts */}
                <Menu iconShape="circle" >
                    <SubMenu title="Accounts" icon={<MdManageAccounts/>}>
                        <MenuItem>List Accounts<Link to="/list-accounts" /></MenuItem>
                        <MenuItem>Add Accounts<Link to="/add-account" /></MenuItem>
                    </SubMenu>
                </Menu>

                {/* Dashboard */}
                <Menu iconShape="circle">
                    <MenuItem icon={<MdDashboardCustomize/>} >Dashboard<Link to="/dashboard" /></MenuItem>
                </Menu>
            </ProSidebar>
        </>
    )
}

export default Sidebar;
