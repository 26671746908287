import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useHistory } from 'react-router';

export default function Login()
{
    const NkAuthBody =
    {
       borderRadius: '20px',
       borderColor: '#ecf0f3',
       backgroundColor: '#fff',
       boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
    }

    const InputBox = 
    {
        boxShadow: 'none',
        borderColor: '#9d72ff',
        backgroundColor: 'transparent'
    }

    const [ AddLoginUsers, setAddLoginUsers ] = useState(
    {
        email_address: '',
        password: '',
    });
    

    const onInputValue = (e) =>
    {
        setAddLoginUsers({...AddLoginUsers, [e.target.name]: e.target.value})
    };
    
    const AddData = async (e) =>
    {
        e.preventDefault();
        await axios.post('http://localhost:3001/Users', AddLoginUsers);
        sessionStorage.setItem('AddLoginUsers', JSON.stringify(AddLoginUsers));
        return <useHistory to="/users" />
    }

    // const Navigate = useHistory();
    const IsAuthorized = window.sessionStorage.getItem("AddLoginUsers");
    useEffect(() =>
    {
        if (IsAuthorized) 
        {
            // alert('IsAuthorized')
        }
    }, [])

     return (
        <div>
            <div className="nk-app-root">
                {/* main @s */}
                <div className="nk-main ">
                    {/* wrap @s */}
                    <div className="nk-wrap nk-wrap-nosidebar">
                        {/* content @s */}
                        <div className="nk-content ">
                            <div className="nk-block nk-block-middle nk-auth-body  wide-xs" style={ NkAuthBody }>
                                <div className="brand-logo">
                                    <a href="" className="logo-link">
                                        <img className="ml-4 pl-1 py-1" src="../assets/images/logo.png" />
                                    </a>
                                </div>
                                <hr />
                                <div className="card login-card" style={{ border: 'none' }}>
                                    <div className="card-inner card-inner-sm">
                                        <div className="nk-block-head">
                                            <div className="nk-block-head-content">
                                                <h4 className="nk-block-title">Log-In</h4>
                                                <div className="nk-block-des">
                                                    <p>Access the Flone Admin Panel using your email and password.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <form className="form-validate is-alter" onSubmit={AddData} >
                                            <div className="form-group">
                                                <div className="form-label-group">
                                                    <label className="form-label" htmlFor="password">Email Address</label>
                                                </div>
                                                <input type="email" className="form-control" id="password" placeholder="Enter your email address" name="email_address"  onChange={onInputValue} style={InputBox} required />
                                            </div>
                                            <div className="form-group">
                                                <div className="form-label-group">
                                                    <label className="form-label" htmlFor="password">Password</label>
                                                </div>
                                                <div className="form-control-wrap">
                                                    <input type="password" className="form-control" id="password" placeholder="Enter your password" name="password" style={InputBox}  onChange={onInputValue} required />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <button className="btn btn-md btn-block ptn-primary text-white font-weight-bold" style={{ background: '#9d72ff', border: '#9d72ff', boxShadow: 'none' }}>Log in</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* wrap @e */}
                    </div>
                    {/* content @e */}
                </div>
                {/* main @e */}
            </div>
            {/* app-root @e */}
          </div>
    )
}
