const carts = JSON.parse(localStorage.getItem('carts'));
// if(carts){
//   props.addToAllProductsHandler(carts);
// }

const initialstate = carts ? carts : [];
const cartData = (state = initialstate, action) => {
	switch (action.type) {
		case "ADD_TOO_CART":

			if (state !== '') {
				const check_index = state.findIndex(item => item.id === action.data.id);
				if (check_index !== -1) {
					state[check_index].quantity = action.data.quantity;
					//   console.log(action.data.quantity * state[check_index].price);
					state[check_index].sub_total = action.data.quantity * action.data.price;
					localStorage.setItem('carts', JSON.stringify(state));
					//   console.log('state update :',state);
					return [
						...state
					]
				} else {
					localStorage.setItem('carts', JSON.stringify(state));
					return [
						...state,
						action.data
					]
				}
			}
			else {
				return [
					...state,
					action.data
				]
			}
		case "REMOVE_TO_CART":
			const filtered = state.filter((ele) => ele.id !== action.data.id);
			state = filtered;
			localStorage.setItem('carts', JSON.stringify(state));
			return state;
		case "DELETE_CARTS":
			return [];
		default:
			return state;
	}
}

export default cartData;