import React, { useState, useEffect } from 'react'
import Sidebar from '../../Sidebar'
// Header
import Header from '../../Header'
// Footer
import Footer from '../../Footer'
import { Link } from 'react-router-dom'
import {Modal,Button} from 'react-bootstrap'
import EditCategories from './EditCategories'
import AddCategories from './AddCategories'
import * as MyConstants from '../../../../Constant/Config' 
 
export default function Categories() 
{
    // Image
    const Image = 
    {
        height: '100px',
        width: '100px',
        borderRadius: '10px'
    }

    // Get Categories Data 
    const [Categories, setCategories] = useState([]);

    useEffect(() => {
        fetch(MyConstants.ListCategories,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((result) => 
        {
            result.json().then((response) => 
            {
                console.warn(response);
                if(response.status == true)
                {
                    setCategories(response.categories);
                }
            })
        })
    }, []);
    console.warn(Categories);

    const edit  = () =>{
        fetch(MyConstants.ListCategories,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((result) => 
        {
            result.json().then((response) => 
            {
                console.warn(response);
                if(response.status == true)
                {
                    setCategories(response.categories);
                }
            })
        })
    }

    // Edit Categories Data
    const [EditModalShow, setEditModalShow] = useState(false);
    const [EditCategoriesModalData, setEditCategoriesModalData] = useState([]);

    const EditCategoriesModalClose = () => setEditModalShow(false);
    const EditCategoriesModalShow = () => setEditModalShow(true);

    const GetCategoriesData = (id, image, code, name, description) =>
    {
        let EditCategoriesModalData = [ id, image, code, name, description ];
        setEditCategoriesModalData([...EditCategoriesModalData]);
        return setEditModalShow(true);
    }

    // Add Categories Data
    const [AddModalShow, setAddModalShow] = useState(false);
    const [AddCategoriesModalData, setAddCategoriesModalData] = useState([]);

    const AddCategoriesModalClose = () => setAddModalShow(false);
    const AddCategoriesModalShow = () => setAddModalShow(true);

    const [ id, setId ] = useState("");
    const [ category, setCategory ] = useState("");
    const [ sub_category, setSubCategory ] = useState("");

    const AddCategoryData = () =>
    {
        setAddCategoriesModalData([...AddCategoriesModalData]);
        return setAddModalShow(true);
    }

    // Delete Category
    const DeleteCategory = (id) => 
    {
        fetch(MyConstants.DeleteCategory + id,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((result) => 
        {
            result.json().then((response) => 
            {
                console.warn(response);
                if(response.status == true)
                {
                    edit();
                }
            })
        })
    }

    return (
        <div>
            <div className="nk-app-root">
                {/* main @s */}
                <div className="nk-main ">
                    {/* sidebar @s */}
                    <div className="nk-sidebar nk-sidebar-fixed is-light " data-content="sidebarMenu">
                        <Sidebar />
                    </div>
                    {/* Sidebar @e */}
                    {/* wrap @s */}
                    <div className="nk-wrap ">
                        {/* main header @s */}
                        <div className="nk-header nk-header-fixed is-light">
                            {/* Header */}
                            <Header />
                        </div>
                        {/* main header @e */}

                        {/* content @s */}
                        <div className="nk-content ">
                            <div className="container-fluid">
                                <div className="nk-content-inner">
                                    <div className="nk-content-body">
                                        <div class="components-preview">
                                            <div className="nk-block-head nk-block-head-sm card p-4" style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', outline: 'none', marginTop: '20px' }}>
                                                <div className="nk-block-between">
                                                    <div className="nk-block-head-content">
                                                        <h3 className="nk-block-title page-title" style={{ color: "#9d72ff" }}>Catrgories</h3>
                                                    </div>{/* .nk-block-head-content */}
                                                    <div className="nk-block-head-content">
                                                        <Button className="btn btn-primary btn-sm d-none d-md-inline-flex" style={{ backgroundColor: "#9d72ff", border: "#9d72ff", outline: "none", boxShadow: "none" }} onClick={ AddCategoryData } ><em className="icon ni ni-plus"/><span>Add Category</span></Button>
                                                    </div>{/* .nk-block-head-content */}
                                                </div>{/* .nk-block-between */}
                                            </div>{/* .nk-block-head */}
                                            <div className="nk-block nk-block-lg mt-5">
                                                <div className="card card-preview" style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', outline: 'none', marginTop: '20px' }}>
                                                    <div className="card-inner">
                                                        <table className="table table-bordered table-hover table-striped table-responsive-sm">
                                                            <thead>
                                                                <tr>
                                                                    <th>Sr#</th>
                                                                    <th>Ctaegory Image</th>
                                                                    <th>Category Code</th>
                                                                    <th>Category Name</th>
                                                                    <th>Description</th>
                                                                    <th>Actions</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    Categories.map(item =>
                                                                    <tr>
                                                                        <td>{item.id}</td>
                                                                        <td>
                                                                            <img src={MyConstants.ImageUrl+`${item.image}`} style={Image}  />
                                                                        </td>
                                                                        <td>{item.code}</td>
                                                                        <td>{item.name}</td>
                                                                        <td>{item.description}</td>
                                                                        <td>
                                                                            <Button className="btn btn-primary d-none  btn-sm d-md-inline-flex" style={{ backgroundColor: "#9d72ff", border: "#9d72ff", outline: "none", boxShadow: "none" }} onClick={() => GetCategoriesData(item.id,item.image, item.code, item.name, item.description)}><em className="icon ni ni-edit" /><span>Edit</span></Button>
                                                                            <Button className="btn btn-danger btn-sm ml-2 d-md-inline-flex"  onClick={() => DeleteCategory(item.id)}><em className="icon ni ni-trash" /><span>Delete</span></Button>
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>{/* .card-preview */}
                                            </div> {/* nk-block */}

                                        </div>{/* .components-preview */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* content @e */}
                        {/* Footer */}
                        <div className="nk-footer">
                            <Footer />
                        </div>
                        {/* footer @e */}
                    </div>
                    {/* wrap @e */}
                </div>
                {/* main @e */}
            </div>
            {/* nk-app-root */}

            {/* EditCategory Modal */}
            {
                EditModalShow === true ? 
                    <Modal className="fade zoom" show={EditCategoriesModalShow} onHide={EditCategoriesModalClose} backdrop="static">
                        <Modal.Header>
                            <Modal.Title>Edit Category</Modal.Title>
                            <a href="#" className="close" onClick={EditCategoriesModalClose}>
                                <em className="icon ni ni-cross" />
                            </a>
                        </Modal.Header>
                        <Modal.Body> 
                            <p>Please fill in the information below. The field labels marked with * are required input fields.</p>
                            {/* Edit Category */}
                            <EditCategories closeModal={EditCategoriesModalClose} id={  EditCategoriesModalData[0] } image={  EditCategoriesModalData[1] } code={  EditCategoriesModalData[2] } name={  EditCategoriesModalData[3] } description={  EditCategoriesModalData[4] } edit={edit} />
                        </Modal.Body>
                    </Modal>
                : ''
            }
            
            {/* AddCategoriesModal */}
            {
                AddModalShow === true ? 
                    <Modal className="fade zoom" show={AddCategoriesModalShow} onHide={AddCategoriesModalClose} backdrop="static">
                        <Modal.Header>
                            <Modal.Title>Add Category</Modal.Title>
                            <a href="#" className="close" onClick={AddCategoriesModalClose}>
                                <em className="icon ni ni-cross" />
                            </a>
                        </Modal.Header>
                        <Modal.Body> 
                            <p>Please fill in the information below. The field labels marked with * are required input fields.</p>
                            {/* Add Categories */}
                            <AddCategories closeModal={AddCategoriesModalClose} edit={edit} />
                        </Modal.Body>
                    </Modal>
                : ''
            }

        </div>
    )
}
