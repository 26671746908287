import React, {useState} from 'react'
import * as MyConstants from '../../../../Constant/Config' 

export default function AddGeneralSettings({closeModal}) 
{
    const Outline = 
    {
        boxShadow: 'none',
        borderColor: '#9d72ff',
        backgroundColor: 'transparent'
    }

    const [ email, setEmail ] = useState("");
    const [ phone, setPhone ] = useState("");
    const [ address, setAddress ] = useState("");
    const [ logo, setLogo ] = useState("");

    // AddGeneralSettings
    const AddSettings = (e) => 
    {
        let data = {"email": email,"phone": phone,"address": address,"logo": logo};
        fetch(MyConstants.addGeneral,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then((result) => 
        {
            result.json().then((response) => 
            {
                if(response.status == true)
                {
                    closeModal(true)
                }
            })
        })
    }

    
    return (
        <div>
            <form className="form-validate is-alter" >
                <div className="form-group" >
                    <label className="form-label" htmlFor="email">Email Address</label>
                    <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                    <div className="form-control-wrap">
                        <input type="email" className="form-control" name="email" placeholder="Enter Email Address" style={Outline} onChange={ (e)=>setEmail(e.target.value) } required />
                    </div>
                </div>
                
                <div className="form-group" >
                    <label className="form-label" htmlFor="phone">Contact No</label>
                    <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                    <div className="form-control-wrap">
                        <input type="number" className="form-control" name="phone" placeholder="Enter Contact No" style={Outline} onChange={ (e)=>setPhone(e.target.value) } required />
                    </div>
                </div>
                
                <div className="form-group" >
                    <label className="form-label" htmlFor="address">Address</label>
                    <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                    <div className="form-control-wrap">
                        <input type="text" className="form-control" name="address" placeholder="Enter Address" style={Outline} onChange={ (e)=>setAddress(e.target.value) } required />
                    </div>
                </div>
                
                <div className="form-group" >
                    <label >Image</label>
                    <input type="file"  name="image" style={{border: 'none', background: 'none'}} />
                </div>

                <div className="modal-footer bg-white"></div>
                <div className="form-group float-right">
                    <button type="button" className="btn btn-sm btn-danger mr-2" onClick={closeModal}>Cancel</button>
                    <button type="submit" className="btn btn-sm btn-primary" style={{ backgroundColor: "#9d72ff", border: "#9d72ff", outline: "none", boxShadow: "none" }} onClick={AddSettings}>Add General Setting</button>
                </div>
            </form>
        </div>
    )
}
