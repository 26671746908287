import React,{useState} from "react"
import {Card,Header,Row,Col,Button,Table,Tabs,Tab} from "react-bootstrap"
import {FaFilter,FaTrash,FaPlus, FaCheck} from "react-icons/fa"
import {Modal} from 'react-bootstrap'

// Styling
import './style.css'

function Create_request()
{

  // Success Modal
  const [ModalShow, setModalShow] = useState(false);

  const SuccessModalClose = () => setModalShow(false);
  const SuccessModalShow = () => setModalShow(true);

  const SuccessModal = () =>
  {
      return setModalShow(true);
  }

  return(
    <>
    <div className="Body">
      <Row className="container-fluid Row" >
        <Col md={6} className="Column" >
          {/* Create Financing Request */}
          <div className="Border" >
            <Card.Header className="Heading" >Create Financing Request</Card.Header>
            <div className="Padding" >
              <label className="Label" >Financing Request Type <span className="LabelSpan" >*</span></label>
              <select className="Select1" ><option>Developer Bank Process</option></select>
            </div>
            <div className="Padding" >
              <label className="Label" >Instruction Type <span className="LabelSpan" >*</span></label>
              <select className="Select2" ><option>Please Select Instruction Type</option></select>
            </div>
          </div>
          
          {/* Initial setting */}
          <div className="Border" >
            <Card.Header className="Heading" >Initial setting</Card.Header>
            <div className="Padding" >
              <label className="Label" >Currency <span className="LabelSpan" >*</span></label>
              <select  className="Select1" ><option>Please select currency</option></select>
            </div>
            <div  className="Padding" >
              <label  className="Label" >Project Code <span className="LabelSpan" >*</span></label>
              <select className="Select2" ><option>Please Select Project Code</option></select>
            </div>
            <div className="Padding" >
              <label className="Label" >Project Name <span className="LabelSpan" >*</span></label>
              <input className="Input"  type='text' />
            </div>
            <div className="Padding">
              <label className="Label">Financial Institution <span className="LabelSpan">*</span></label>
              <input type='text' className="Input" />
            </div>
          </div>
        </Col>

        {/* General Information */}
        <Col md={6} className="Column" >
          <div className="Border" >
            <Card.Header className="Heading">General Information</Card.Header>
            <div className="Padding">
              <label className="Label">Approval Route <span className="LabelSpan">*</span></label>
              <select className="Select1"><option>Please Select Instruction Type</option></select>
            </div>
            <div className="Padding">
              <label className="Label">Approval Sequence </label><input type='text' className="Input"/>
            </div>
            <div className="Padding">
              <label className="Label">Next Approval </label><input type='text' className="Input"/>
            </div>
            <div className="Padding">
              <label className="Label">Requester </label><input type='text' className="Input"/>
            </div>
            <div className="Padding">
              <label className="Label">Summited Date </label><input type='text' className="Input"/>
            </div>
            <div className="Padding">
              <label className="Label">Status </label><input type='text' className="Input"/>
            </div>
            <div className="Padding">
              <label className="Label">Batch ID </label><input type='text' className="Input"/>
            </div>
          </div>

          <div className="Border">
            <Card.Header className="Heading">Loan Information</Card.Header>
            <div className="Padding">
              <label className="Label">Facility Name <span className="LabelSpan">*</span></label><input type='text' className="Input"/>
            </div>
            <div className="Padding">
              <label className="Label">Facility Agreement/Letter of offer Date </label><input type='text' className="Input"/>
            </div>
            <div className="Padding">
              <label className="Label">Loan Account No. (For Drawdown) </label><input type='text' className="Input"/>
            </div>
            <div className="Padding">
              <label className="Label">Project Account <span className="LabelSpan">*</span></label><select className="Select2"><option>Please Select Project Account</option></select>
            </div>
            <div className="Padding">
              <label className="Label">Loan Type <span className="LabelSpan">*</span></label>
              <input type="radio" value="Bilateral" name="loanType" className="RadioInput" /> Bilateral
            <input type="radio" className="RadioInput" value="Syndication" name="loanType" /> Syndication
            </div>
            <div className="Padding">
              <label className="Label">Drawdown Date<span className="LabelSpan">*</span> (Tenor Start Date) </label><input type='date' className="Input2" placeholder='Please Select Loan End Date'/>
            </div>
            <div className="Padding">
              <label className="Label">Loan End Date <span className="LabelSpan">*</span></label><input type='date' className="Input2" placeholder='Please Select Loan End Date'/>
            </div>
          </div>
        </Col>
      </Row>

      <Row className="container-fluid Row">
        <Col md={12}>
          <Row>
            <Col md={6}>
              <h3>Invoice and Instruction Amount</h3>
            </Col>
            <Col md={6}>
              <div className="FloatRight" >
              <Button variant="secondary" className="RemoveButton" ><FaTrash className="RemoveIcon"/>Remove</Button>
              <Button variant="success" className="DownloadButton">Download CSV</Button>
              <Button variant="secondary" className="AddButton"><FaPlus className="AddIcon"/>Add New</Button>
              </div>
            </Col>
          </Row>

          <i>Please Select The Invoice and Payment Account</i>

        <div className="BoxShadow card mt-3 p-0">
          <Table bordered hover className="Table mt-0">
            <thead>
              <tr className="TableRow">
                <th><input type='checkbox' className="CheckBox" />Invoice No</th>
                <th>Supplier/Beneficiary</th>
                <th>Invoice Type</th>
                <th>Payment Mode</th>
                <th>GST Applicable?</th>
                <th>Total Invoice Amount</th>
                <th>Paid Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><input type='checkbox' className="CheckBox"/><a href='#'>sdsd122</a></td>
                <td>Mark</td>
                <td>Full</td>
                <td>SWIFT</td>
                <td>Yes</td>
                <td>SGD 2,000,000.00</td>
                <td>0</td>
              </tr>
              <tr>
                <td><input type='checkbox' className="CheckBox"/><a href='#'>sdsd122</a></td>
                <td>Mark</td>
                <td>Full</td>
                <td>SWIFT</td>
                <td>Yes</td>
                <td>SGD 2,000,000.00</td>
                <td>0</td>
              </tr>
              <tr>
                <td><input type='checkbox' className="CheckBox"/><a href='#'>sdsd122</a></td>
                <td>Mark</td>
                <td>Full</td>
                <td>SWIFT</td>
                <td>Yes</td>
                <td>SGD 2,000,000.00</td>
                <td>0</td>
              </tr>
            </tbody>
          </Table>
          <Row className="container-fluid Row2 ml-0 mr-0">
            <Col md={5}>
              <h3>Total Batch Amount</h3>
            </Col>
            <Col md={5} className="TextCenter">
              SGD 2,000,000.00
            </Col>
          </Row>
          <Row className="ml-3">
            <Col md={5}>
              Total Amount Drawdown From Project Amount<span className="LabelSpan">*</span>
            </Col>
            <Col md={5}>
              <input type='text' className='form-control' value='2,000,000' />
            </Col>
          </Row>
          <Row className="mt-2 ml-3">
            <Col md={5}>
              Total Amount Drawdown From Loan Amount<span className="LabelSpan">*</span>
            </Col>
            <Col md={5}>
              <input type='text' className='form-control' value='4,000,000' />
            </Col>
          </Row>
          <Row className="mt-2 mb-3 ml-3">
            <Col md={5}>
              Total Amount Drawdown From Current Amount<span className="LabelSpan">*</span>
            </Col>
            <Col md={5}>
              <input type='text' className='form-control' value='6,000,000' />
            </Col>
          </Row>
        </div>

        <div className="BoxShadow2">
          <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3">
            <Tab eventKey="home" title="Home">
              <Row>
                <Col md={6}>
                <div className="Padding">
                  <label className="InputWidth40">Supplier/Beneficiary Name </label><input type='text' className="Input"/>
                </div>
                <div className="Padding">
                  <label className="InputWidth40">Beneficiary Company Registration No. </label><input type='text' className="Input"/>
                </div>
                <div className="Padding">
                  <label className="InputWidth40">Beneficiary Tax No. </label><input type='text' className="Input"/>
                </div>
                <div className="Padding">
                  <label className="InputWidth40">Beneficiary Address line 1 </label><input type='text' className="Input"/>
                </div>
                <div className="Padding">
                  <label className="InputWidth40">Beneficiary Address line 2 </label><input type='text' className="Input"/>
                </div>
                <div className="Padding">
                  <label className="InputWidth40">Beneficiary Address line 3 </label><input type='text' className="Input"/>
                </div>
                <div className="Padding">
                  <label className="InputWidth40">Country </label><input type='text' className="Input"/>
                </div>
                <div className="Padding">
                  <label className="InputWidth40">Payment Mode </label>
                  <select className="Select1"><option>Developer Bank Process</option></select>
                </div>
                </Col>
                <Col md={6}>
                <div className="Padding">
                  <label className="InputWidth40">Beneficiary Bank Name </label><input type='text' className="Input"/>
                </div>
                <div className="Padding">
                  <label className="InputWidth40">Beneficiary Bank Swift Code </label><input type='text' className="Input"/>
                </div>
                <div className="Padding">
                  <label className="InputWidth40">Beneficiary Bank Name </label><input type='text' className="Input"/>
                </div>
                <div className="Padding">
                  <label className="InputWidth40">Beneficiary Bank Account </label>
                  <select className="Select1"><option>Developer Bank Process</option></select>
                </div>
                <div className="Padding">
                  <label className="InputWidth40">Beneficiary Payable Name </label><input type='text' className="Input"/>
                </div>
                <div className="Padding">
                  <label className="InputWidth40">Intermediary Bank Name </label><input type='text' className="Input"/>
                </div>
                <div className="Padding">
                  <label className="InputWidth40">Intermediary Bank Swift Code </label><input type='text' className="Input"/>
                </div>
                <div className="Padding">
                  <label className="InputWidth40">SORT Code (for EUR/AUD) </label><input type='text' className="Input"/>
                </div>
                <div className="Padding">
                  <label className="InputWidth40">CO Collection Method </label>
                  <select className="Select1"><option>Developer Bank Process</option></select>
                </div>
                </Col>
              </Row>
              <Row>
              <Col md={4}>
              <label className="FlaotRight2">Payment Description</label> 
              </Col>
              <Col md={8}>
              <textarea cols={100} rows={5} />
              </Col>
              </Row>
            </Tab>
            <Tab eventKey="profile" title="Profile">
              asdasdasdasdwwwww
            </Tab>
            <Tab eventKey="contact" title="Contact" disabled>
              asdasdasdasd2222222
            </Tab>
          </Tabs>
          </div>

          <Row className="MarginTop5">
            <Col md={5}>
              <h3>Document</h3>
            </Col>
            <Col md={7}>
              <Button variant="secondary" className="MarginRight2">Route to E-signature</Button>
              <Button variant="secondary">Add system generated document</Button>
            </Col>
          </Row>

          <Table bordered hover className="Table">
            <thead>
              <tr className="TableRow">
                <th><input type='checkbox' className="CheckBox"/>Document Type</th>
                <th>Version</th>
                <th>E-signatured required?</th>
                <th>Attachment file</th>
                <th>Size?</th>
                <th>Type</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><input type='checkbox' className="CheckBox"/>Grant of Written Permission</td>
                <td>1.0</td>
                <td>No</td>
                <td><a href='#'>Click to upload your file</a></td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td><input type='checkbox' className="CheckBox"/>Grant of Written Permission</td>
                <td>1.0</td>
                <td>No</td>
                <td><a href='#'>Click to upload your file</a></td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td><input type='checkbox' className="CheckBox"className="CheckBox"/>Grant of Written Permission</td>
                <td>1.0</td>
                <td>No</td>
                <td><a href='#'>Click to upload your file</a></td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td><input type='checkbox' className="CheckBox"/>Grant of Written Permission</td>
                <td>1.0</td>
                <td>No</td>
                <td><a href='#'>Click to upload your file</a></td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td><input type='checkbox' className="CheckBox"/>Grant of Written Permission</td>
                <td>1.0</td>
                <td>No</td>
                <td><a href='#'>Click to upload your file</a></td>
                <td>-</td>
                <td>-</td>
              </tr>
            </tbody>
          </Table>

          <h3 className="MarginTop5">Conversation</h3>
          <div className="OrangeBorder" style={{boxShadow: '0 4px 8px 0 rgba(0,0,0,0.3), 0 6px 20px 0 rgba(0,0,0,0.19)'}}>
          <label className="Padding20">Internal Conversation</label>
          <Tabs defaultActiveKey="conversation" id="uncontrolled-tab-example" className="mb-3">
            <Tab eventKey="conversation" title="Conversation">
              <Row className="MarginBottom3">
                <Col md={10} className="TextCenter">
                  <input type='text' className='form-control FloatRight2' placeholder='Enter Your Comment Here...'/>
                </Col>
                <Col md={2} className="TextCenter">
                  <Button variant="success" classNmae="MarginRight2">Send</Button>
                </Col>
              </Row>
              <Card  style={{marginBottom: '30px'}}>
                <Table>
                    <thead>
                    <tr className="TableRow">
                      <th>User</th>
                      <th>Role</th>
                      <th>Date</th>
                      <th>Comment</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr style={{ height: '70px' }}>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </Table>
              </Card>
            </Tab>
            <Tab eventKey="attachment" title="Attachment">
              Attachment
            </Tab>
          </Tabs>
          </div>


          <h3 className="MarginTop5">Audit Trail</h3>
          <div className="BoxShadow3">
          <label className="Paddig20">Audit Trail</label>
            <div className="Padding">
            <Table bordered className="TableRow" align='center'>
              <thead>
                <tr className="TableRow">
                  <th>User</th>
                  <th>Role</th>
                  <th>Action</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                <tr className="TextCenter">
                  <td><input type='text' className="TableColumn"/><FaFilter/></td>
                  <td><input type='text' className="TableColumn"/><FaFilter/></td>
                  <td><input type='text' className="TableColumn"/><FaFilter/></td>
                  <td><input type='text' className="TableColumn"/><FaFilter/></td>
                </tr>
              </tbody>
            </Table>
            </div>
          </div>
          

          <div>
            <h3 style={{marginTop: '5%'}}>Checklist Declaration</h3>
            <input type='checkbox' style={{height: '15px', width: '15px', marginTop: '2px', float: 'left'}} />
            <p style={{marginLeft: '2%'}}>We hereby certify that the attached copies of the supporting documents are true and complete for its purpose.</p>
            <Card className="p-3" style={{marginTop: '5%'}}>
              <Row>
              <Col md={6}>
                  <Button variant="secondary">Back</Button>
              </Col>
              <Col md={6}>
                <div className="float-right">
                <Button variant="danger" style={{marginRight: '15px'}}>Save as Draft</Button>
                <Button variant="success" onClick={SuccessModal}>Submit</Button>
                </div>
              </Col>
              </Row>
            </Card>
          </div>
        </Col>
      </Row>
      
      {/* SuccessModal */}
      {
        ModalShow === true ? 
            <Modal show={SuccessModalShow} onHide={SuccessModalClose}>
                <Modal.Header style={{border: 'unset'}}>
                  <Modal.Title ><FaCheck style={{color: 'green'}} /><span style={{marginLeft: '30px'}}>Success!</span></Modal.Title>
                    <a href="#" className="close" style={{fontSize: '30px', marginRight: '30px'}} onClick={SuccessModalClose}>
                        <em className="icon ni ni-cross" />
                    </a>
                </Modal.Header>
                <Modal.Body> 
                  <p style={{fontSize: '20px', marginLeft: '50px'}}>Developer Processing Request has been updated successfully</p>
                  </Modal.Body>
                  <Modal.Footer style={{border: 'unset', marginRight: 'auto'}}>
                    <Button variant="success" style={{marginLeft: '50px'}}>I Understand</Button>
                    <Button variant="white" style={{color: 'green'}} onClick={SuccessModalClose}>Cancel</Button>
                  </Modal.Footer>
            </Modal>
        : ''
    }
    </div>
    </>
  )
}

export default Create_request;