import React, { useState, useEffect } from 'react'
import * as MyConstants from '../../../../Constant/Config' 
import axios from 'axios'

export default function EditTaggs({ closeModal, edit, id, product, popular, featured, discount }) 
{
    const Outline = 
    {
        boxShadow: 'none',
        borderColor: '#9d72ff',
        backgroundColor: 'transparent'
    }

    const [ EditTaggProducts, setTaggProducts ] = useState(
    {
        id: id,
        product: product,
        popular: popular,
        featured: featured,
        discount: discount,
    });

    console.log(EditTaggProducts);
    
    const onInputEditValue = (e) =>
    {
        setTaggProducts({...EditTaggProducts, [e.target.name]: e.target.value})
    };

    // Get Products Data 
    const [Products, setProducts] = useState([]);
    useEffect(() => {
        fetch(MyConstants.listProduct,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((result) => 
        {
            result.json().then((response) => 
            {
                console.warn(response);
                if(response.status == true)
                {
                    setProducts(response.products);
                }
            })
        });
    },[]);

    // checkedPopular
    const checkedPopular = (e) => {
        const checked = e.target.checked;
        if(checked)
        {
            setTaggProducts({...EditTaggProducts, ['popular']: "Popular"})
        }
        else{
            setTaggProducts({...EditTaggProducts, ['popular']: ''})
        }
    }

    // checkedFeatured
    const checkedFeatured = (e) => {
        const checked = e.target.checked;
        if(checked)
        {
            setTaggProducts({...EditTaggProducts, ['featured']: "Featured"})
        }
        else{
            setTaggProducts({...EditTaggProducts, ['featured']: ''})
        }
    }

    const UpdateData = () =>
    {
        console.log(EditTaggProducts);
        const formData =  new FormData();
		formData.append('id',EditTaggProducts.id);
		formData.append('product',EditTaggProducts.product);
        formData.append('popular',EditTaggProducts.popular); 
        formData.append('featured',EditTaggProducts.featured);
        formData.append('discount',EditTaggProducts.discount); 
		axios.post(MyConstants.updateTaggedProduct,formData)
		.then((result)=>{
			if(result.data.status == true){
                console.log(result.data.message);
                closeModal(true);
                edit();
            }
		})
    }

    return (
        <div>
            <div className="form-group" >
                <label className="form-label" htmlFor="">Products</label>
                <span style={{ color: 'red', marginLeft: '2px' }}>*</span>
                <select className='form-control custom-select' name='product'>
                { Products.map(item =>
                    (EditTaggProducts.product === item.id ) ? (<option  value={item.id} selected>{item.name}</option>) : (<option  value={item.id}>{item.name}</option>)
                )}
                </select>
            </div>

            <div className="form-group" >
                <label className="form-label" htmlFor="discount">Discounts</label>
                <span style={{ color: 'red', marginLeft: '2px' }}>*</span>
                <div className="form-control-wrap">
                    <input type="number" className="form-control" name="discount" placeholder="Enter Discount" style={Outline} defaultValue={discount} onChange={onInputEditValue}  required />
                </div>
            </div>

            <div className='row mb-3'>
                <div className='col'>
                    <div className="custom-control custom-checkbox">
                        {(EditTaggProducts.popular == "Popular") ? (<input type="checkbox" className="custom-control-input" id="popular" onChange={checkedPopular} checked/>) : (<input type="checkbox" className="custom-control-input" id="popular" onChange={checkedPopular} />)}
                        <label className="custom-control-label" htmlFor="popular">
                            <h4>Popular</h4>
                        </label>
                    </div>
                </div>
                <div className='col'>
                    <div className="custom-control custom-checkbox">
                        {(EditTaggProducts.featured == "Featured") ? (<input type="checkbox" className="custom-control-input" id="featured" onChange={checkedFeatured} checked/>) : (<input type="checkbox" className="custom-control-input" id="featured" onChange={checkedFeatured} />)}
                        <label className="custom-control-label" htmlFor="featured">
                            <h4>Featured</h4>
                        </label>
                    </div>
                </div>
            </div>

            <div className="modal-footer bg-white"></div>
            <div className="form-group float-right">
                <button type="button" className="btn btn-sm btn-danger mr-2" onClick={closeModal}>Cancel</button>
                <button className="btn btn-sm btn-primary" onClick={UpdateData} >Update</button>
            </div>
        </div>
    )
}
 