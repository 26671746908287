import React, { useState, useEffect } from 'react'
import Sidebar from '../../Sidebar'
// Header
import Header from '../../Header'
// Footer
import Footer from '../../Footer'
import {Modal,Button} from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom';
import * as MyConstants from '../../../../Constant/Config'
// Image
const Image = 
{
    height: '100px',
    width: '100px',
    borderRadius: '10px'
}
 
export default function ListUsers() 
{
    // Get ListUsers Data 
    const [ListUsers, setListUsers] = useState([]);

    useEffect(() => {
        fetch(MyConstants.listUser,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((result) => 
            {
                result.json().then((response) => 
                {
                    console.warn(response);
                    if(response.status == true)
                    {
                        setListUsers(response.users);
                    }
                })
            })
    }, []);
    console.warn(ListUsers);

    const History = useHistory();
    const NavigateTo = () => History.push('/add-users');
    const NavigateToEditUser = () => History.push('/edit-users');

    return (
        <div>
            <div className="nk-app-root">
                {/* main @s */}
                <div className="nk-main ">
                    {/* sidebar @s */}
                    <div className="nk-sidebar nk-sidebar-fixed is-light " data-content="sidebarMenu">
                        <Sidebar />
                    </div>
                    {/* Sidebar @e */}
                    {/* wrap @s */}
                    <div className="nk-wrap ">
                        {/* main header @s */}
                        <div className="nk-header nk-header-fixed is-light">
                            {/* Header */}
                            <Header />
                        </div>
                        {/* main header @e */}

                        {/* content @s */}
                        <div className="nk-content ">
                            <div className="container-fluid">
                                <div className="nk-content-inner">
                                    <div className="nk-content-body">
                                        <div class="components-preview">
                                            <div className="nk-block-head nk-block-head-sm card p-4" style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', outline: 'none', marginTop: '20px' }}>
                                                <div className="nk-block-between">
                                                    <div className="nk-block-head-content">
                                                        <h3 className="nk-block-title page-title" style={{ color: "#9d72ff" }}>Users</h3>
                                                    </div>{/* .nk-block-head-content */}
                                                    <div className="nk-block-head-content">
                                                        <Button className="btn btn-primary btn-sm d-none d-md-inline-flex" style={{ backgroundColor: "#9d72ff", border: "#9d72ff", outline: "none", boxShadow: "none" }} onClick={ NavigateTo } ><em className="icon ni ni-plus"/><span>Add User</span></Button>
                                                    </div>{/* .nk-block-head-content */}
                                                </div>{/* .nk-block-between */}
                                            </div>{/* .nk-block-head */}
                                            <div className="nk-block nk-block-lg mt-5">
                                                <div className="card card-preview" style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', outline: 'none', marginTop: '20px' }}>
                                                    <div className="card-inner">
                                                        <table className="table table-bordered table-hover table-striped table-responsive-sm">
                                                            <thead>
                                                                <tr>
                                                                    <th>Sr#</th>
                                                                    <th>Name</th>
                                                                    <th>Email Address</th>
                                                                    <th>Role</th>
                                                                    <th>Actions</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                            {
                                                                ListUsers.map(item =>
                                                                <tr>
                                                                    <td>{item.id}</td>
                                                                    <td>{item.name}</td>
                                                                    <td>{item.email}</td>
                                                                    <td>{item.role}</td>
                                                                    <td>
                                                                        <Link to={"/edit-users/" + item.id} className="btn btn-primary d-none  btn-sm d-md-inline-flex" style={{ backgroundColor: "#9d72ff", border: "#9d72ff", outline: "none", boxShadow: "none" }}><em className="icon ni ni-edit" /><span>Edit</span></Link>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>{/* .card-preview */}
                                            </div> {/* nk-block */}

                                        </div>{/* .components-preview */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* content @e */}
                        {/* Footer */}
                        <div className="nk-footer">
                            <Footer />
                        </div>
                        {/* footer @e */}
                    </div>
                    {/* wrap @e */}
                </div>
                {/* main @e */}
            </div>
            {/* nk-app-root */}

        </div>
    )
}
