import React, {useState} from 'react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from '@ckeditor/ckeditor5-react';
import * as MyConstants from '../../../../Constant/Config' 
import axios from 'axios' 

export default function AddCategories({closeModal, edit}) 
{
    const Outline = 
    {
        boxShadow: 'none',
        borderColor: '#9d72ff',
        backgroundColor: 'transparent'
    }
    
    // AddData
    const [ name, setName ] = useState("");
    const [ code, setCode ] = useState("");
    const [ description, setDescription ] = useState("");
    const [image, setImage] = useState("");
    console.log(description)

    // CKEditor
    const handleCkeditorState = (e, editor) =>
    {
        const data = editor.getData();
        console.log(data);
    }
    
    const AddData = (e) => 
    {
        const formData =  new FormData();
		formData.append('name',name);
        formData.append('code',code);
        formData.append('description',description);
        formData.append('image',image);
		axios.post(MyConstants.addCategory,formData)
		.then((result)=>{
			if(result.data.status == true){
                closeModal(true);
                edit();
            }
		})
    }
    
    return (
        <div>
                <div className="form-group" >
                    <label className="form-label" htmlFor="code">Category Code</label>
                    <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                    <div className="form-control-wrap">
                        <input type="text" className="form-control" name="code" placeholder="Enter Category Code" style={Outline} onChange={ (e)=>setCode(e.target.value) } required />
                    </div>
                </div>
                
                <div className="form-group" >
                    <label className="form-label" htmlFor="name">Category Name</label>
                    <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                    <div className="form-control-wrap">
                        <input type="text" className="form-control" name="name" placeholder="Enter Category Name" style={Outline} onChange={ (e)=>setName(e.target.value) } required />
                    </div>
                </div>
                
                <div className="form-group" >
                    <label className="form-label" htmlFor="description">Description</label>
                    <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                    <div className="form-control-wrap">
                        <input type="text" className="form-control" name="description" placeholder="Enter Description" style={Outline} onChange={ (e)=>setDescription(e.target.value) } required />
                    </div>
                </div>
                
                <div className="form-group" >
                    <label >Image</label>
                    <input type="file" onChange={(e)=>setImage(e.target.files[0])}  name="image" style={{border: 'none', background: 'none'}} />
                </div>

                <div className="modal-footer bg-white"></div>
                <div className="form-group float-right">
                    <button type="button" className="btn btn-sm btn-danger mr-2" onClick={closeModal}>Cancel</button>
                    <button className="btn btn-sm btn-primary" style={{ backgroundColor: "#9d72ff", border: "#9d72ff", outline: "none", boxShadow: "none" }} onClick={AddData}>Add Category</button>
                </div>
        </div>
    )
}
