import PropTypes from "prop-types";
// import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { connect } from "react-redux";
import { AiOutlineShop } from 'react-icons/ai'
import LayoutOne from "../layouts/LayoutOne";
import { Link } from "react-router-dom";
import Breadcrumb from "../wrappers/breadcrumb/Breadcrumb";
// import RelatedProductSlider from "../../wrappers/product/RelatedProductSlider";
// import ProductDescriptionTab from "../../wrappers/product/ProductDescriptionTab";
// import ProductImageDescription from "../../wrappers/product/ProductImageDescription";
import React, { useState, useEffect, Fragment } from 'react'
import * as MyConstants from '../Constant/Config'
import { Row, Col, Button, Card } from 'react-bootstrap'
import '../pages/home/home.css'
import {AiTwotoneShop} from 'react-icons/ai'
import SkeletonProductList from './SkeletonProductList'


export default function ProductList() {
    const [ProductList, setProductList] = useState([]);
    const [Loading, setLoading] = useState(false);

    useEffect(() => {
        // Skeleton Loading
        setLoading(true);
        fetch(MyConstants.listProduct,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((result) => {
                result.json().then((response) => {
                    console.warn(response);
                    if (response.status == true) {
                        setLoading(false);
                        setProductList(response.products);
                    }
                    console.log(response.products)
                })
            })
    }, []);

    return (

        <Fragment>
            <MetaTags>
                <title>E-Mandi || Product List</title>
                <meta
                    name="description"
                    content="Product page of E-mandi"
                />
            </MetaTags>

            <BreadcrumbsItem to={process.env.PUBLIC_URL + "/"}>Home</BreadcrumbsItem>
            <BreadcrumbsItem to="/product-list/">
                Product List
            </BreadcrumbsItem>

            <LayoutOne headerTop="visible">
                {/* breadcrumb */}
                <Breadcrumb />
                    <div className="container">
                        <Row className="my-5">
                        {!Loading ?
                            ProductList.map((item) =>
                                <div className="col-xl-3 col-md-3 col-lg-3 col-sm-6" >
                                    <div className='product-wrap'>
                                        <div className="product-img">
                                            <Link to={"product-detail/" + item.id}>
                                                <img
                                                    className="default-img"
                                                    src={MyConstants.ImageUrl + item.image}
                                                    height="300"

                                                />
                                            </Link>
                                            {item.discount ? (
                                                <div className="product-img-badges">
                                                    {item.discount ? (
                                                        <span className="pink">-{item.discount}%</span>
                                                    ) : (
                                                        ""
                                                    )}
                                                    {/* {Popular.new ? <span className="purple">New</span> : ""} */}
                                                </div>
                                            ) : (
                                                ""
                                            )}

                                            <div className="product-action">
                                                <div className="pro-same-action pro-cart w-75">
                                                    <button><AiTwotoneShop className="mr-2 mb-1" />{item.store_name}</button>
                                                </div>
                                                <div className="pro-same-action pro-quickview">
                                                    <button title="Quick View">
                                                        <i className="pe-7s-look" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="product-content text-center">
                                            <h3>
                                                <Link to={"product-detail/" + item.id}>
                                                    {item.name}
                                                </Link>
                                            </h3>
                                            <div className="product-price mt-2">
                                                <Fragment>
                                                    <span>{item.crncysymbol + " " + item.price}</span>{" "}
                                                </Fragment>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            )
                            : 
                            [1,2,3,4].map((item) =>
                                <div className="col-xl-3 col-md-3 col-lg-3 col-sm-6" key={item} >
                                    <SkeletonProductList />
                                </div>
                            )
                        }
                        </Row>
                    </div>
            </LayoutOne>
        </Fragment>
    );
};

