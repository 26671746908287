import PropTypes from "prop-types";
// import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { connect } from "react-redux";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
// import RelatedProductSlider from "../../wrappers/product/RelatedProductSlider";
// import ProductDescriptionTab from "../../wrappers/product/ProductDescriptionTab";
// import ProductImageDescription from "../../wrappers/product/ProductImageDescription";
import React, { useState, useEffect, Fragment } from 'react'
import * as MyConstants from '../../Constant/Config'
import { Row, Col, Button, Table } from 'react-bootstrap'
import '../home/home.css'
import { useHistory } from "react-router-dom";
import { Spinner } from "react-bootstrap";



export default function Carts(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [carts, setCarts] = useState([]);
    const [countries, setCountries] = useState([]);
    const [stateProvinces, setStateProvinces] = useState([]);
    const [cities, setCities] = useState([]);
    const [state, setState] = useState([]);
    const [deliveryAddress, setDeliveryAddress] = useState([]);
    const [countryforcity, setCountry] = useState('');
    const [stateProvinceforcity, setStateProvince] = useState('');
    const [city, setCity] = useState('');
    let country , stateProvince;
    const [zipCode, setZipCode] = useState('');


    let orderSubTotal = 0;
    let shipping = 0;

    const nav = useHistory();


    useEffect(() => {
        if (JSON.parse(localStorage.getItem('carts'))) {
            // localStorage.setItem('carts', JSON.stringify(props.cartItems.cartData));
            setCarts(JSON.parse(localStorage.getItem('carts')));
        }

        fetch(MyConstants.listCountries,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((result) => 
            {
                result.json().then((response) => 
                {
                    if(response.status == true)
                    {
                        setCountries(response.countries);
                    }
                })
            })
    }, []);

    const updateQnty = (data, e) => {
        console.log('data', data, 'quantity', e.target.value);
        props.addToAllProductsHandler({ id: data.id, quantity: e.target.value, price: data.price, sub_total: data.sub_total });
        setCarts(JSON.parse(localStorage.getItem('carts')));
    }

    const deleteCart = (data) => {
        props.removeToAllProductsHandler(data);
        setCarts(JSON.parse(localStorage.getItem('carts')));
    }

    const updateCountry = (e) =>{
        country = e.target.value;

        setCountry(e.target.value);

        let data = {'country':country};

        fetch(MyConstants.listStateProvince,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then((result) => 
            {
                result.json().then((response) => 
                {
                    if(response.status == true)
                    {
                        setStateProvinces(response.state_provinces);
                    }
                })
            })
    }

    const updateStateProvince = (e) =>{
        stateProvince = e.target.value;

        setStateProvince(e.target.value);

        let data = {'country':countryforcity,'state_province':stateProvince};

        fetch(MyConstants.listCity,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then((result) => 
            {
                result.json().then((response) => 
                {
                    if(response.status == true)
                    {
                        setCities(response.cities);
                    }
                })
            })
    }

    const checkout = () => {
        let delivery_data = {
            'items_total': orderSubTotal,
            'order_total': orderSubTotal + shipping,
            'email': '',
            'first_name': '',
            'last_name': '',
            'delivery_address': '',
            'country': countryforcity,
            'state_province': stateProvinceforcity,
            'phone': '',
            'city': city,
            'zip_postal_code': zipCode,
            'shipping': shipping
    
        };
        if (JSON.parse(localStorage.getItem('user-info'))) {
            localStorage.setItem('delivery_data',JSON.stringify(delivery_data));
            nav.push('/checkout/')
        } else {
            alert('Please login first');
        }
    }

        carts.forEach(a => {
            orderSubTotal += a.sub_total;
        });

        if(orderSubTotal < 1000){
            shipping = 150;
        }


    return (

        <Fragment>
            <MetaTags>
                <title>E-Mandi || Carts</title>
                <meta
                    name="description"
                    content="Product page of flone react minimalist eCommerce template."
                />
            </MetaTags>

            <BreadcrumbsItem to={process.env.PUBLIC_URL + "/"}>Home</BreadcrumbsItem>
            <BreadcrumbsItem to="/product-detail/">
                Shop Product
            </BreadcrumbsItem>

            <LayoutOne headerTop="visible">
                {/* breadcrumb */}
                <Breadcrumb />

                <div>
                    <div className="container">
                        <Row className="my-5">
                            <Col className="col-md-7">
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Product Name</th>
                                            <th>Quantity</th>
                                            <th>Price</th>
                                            <th>Sub total</th>
                                            <th>Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {carts.map((item) =>
                                            <tr>
                                                <td>{item.id}</td>
                                                <td>{item.name}</td>
                                                <td><input type="number" min={1} onChange={(e) => updateQnty(item, e)} style={{ backgroundColor: 'aquamarine' }} defaultValue={item.quantity} /></td>
                                                <td>{item.price}</td>
                                                <td>{item.sub_total}</td>
                                                <td><button className="btn btn-danger" onClick={() => deleteCart({ id: item.id })}>Delete</button></td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </Col>
                            <Col className="col-md-5">
                                <h2>Summary</h2>
                                <p>Enter your destination to get a shipping estimate.</p>
                                Country<br />
                                <select onChange={updateCountry} className="form-control">
                                    <option>Countries</option>
                                    {countries.map(item =>
                                    <option value={item.id}>{item.country_name}</option>
                                        )}
                                </select>
                                State/Province<br />
                                <select onChange={updateStateProvince} className="form-control" style={{ border: '2 solid #eceff8 !important', height: '45px' }}>
                                <option>State/Province</option>
                                    {stateProvinces.map(item =>
                                    <option value={item.id}>{item.state_province_name}</option>
                                        )}
                                </select>
                                City<br />
                                <select onChange={(e)=>setCity(e.target.value)} className="form-control" style={{ border: '2 solid #eceff8 !important', height: '45px' }}>
                                <option>City</option>
                                {cities.map(item =>
                                    <option value={item.id}>{item.city_name}</option>
                                        )}
                                </select>
                                Zip/Postal Code<br />
                                <input type="text" onChange={(e)=>setZipCode(e.target.value)} style={{ background: 'unset' }} />
                                {/* Local<br />
                                <input type="checkbox" style={{ background: 'unset', height: 'unset', width: 'unset' }} /> Shipping <b>PKR 0.00</b> */}
                                <Table>
                                    <tbody>
                                        <tr>
                                            <td>Subtotal</td>
                                            <td>PKR {orderSubTotal}</td>
                                        </tr>
                                        <tr style={{ border: 'none' }}>
                                            <td>Shipping</td>
                                            <td>PKR {shipping}</td>
                                        </tr>
                                        <tr>
                                            <th>Order Total</th>
                                            <th>{orderSubTotal + shipping}</th>
                                        </tr>
                                    </tbody>
                                </Table>
                                <button className="btn btn-info" onClick={checkout}>PROCEED TO CHECKOUT</button>
                            </Col>
                        </Row>
                        {!isLoading ? '' : <Spinner style={{ marginLeft: "150px" }} animation={'border'} />}
                    </div>
                </div>

            </LayoutOne>
        </Fragment>
    );
};
