import React, { useState, useEffect } from 'react'
import Sidebar from '../../Sidebar'
// Header
import Header from '../../Header'
// Footer
import Footer from '../../Footer'
import { Modal, Button } from 'react-bootstrap'
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as MyConstants from '../../../../Constant/Config'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import swal from 'sweetalert'

export default function AddAccount() {

    const [name, setName] = useState("");
    const [type, setType] = useState("");
    const [opening_balance, setOpeningBalance] = useState("");
    const [deefault, setDefaults] = useState('0');

    const History = useHistory();
    const Navigate = () => History.push('/list-accounts');

    // checkedDefault
    const checkedDefault = (e) => {
        const checked = e.target.checked;
        if(checked)
        {
            setDefaults('1');
        }
        else{
            setDefaults("0");
        }
    }

    // AddData
    const AddData = () => {
        let data = {'name': name, 'type': type, 'opening_balance': opening_balance, 'default': deefault};
        console.log(data);
        fetch(MyConstants.addAccount,{
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then((result) => 
        {
            result.json().then((response) => 
            {
                console.warn(response);
                if(response.status == true)
                {
                    console.log(response.status);
                    History.push('/list-accounts');
                    swal({
                        title: "Success!",
                        text: "New account has been addedd successfully!",
                        icon: "success",
                        button: "Ok",
                    });
                }
            })
        })
    }

    console.log(name);

    return (
        <div>
            <div>
                <div className="nk-app-root">
                    {/* main @s */}
                    <div className="nk-main ">
                        {/* sidebar @s */}
                        <div className="nk-sidebar nk-sidebar-fixed is-light " data-content="sidebarMenu">
                            <Sidebar />
                        </div>
                        {/* Sidebar @e */}
                        {/* wrap @s */}
                        <div className="nk-wrap ">
                            {/* main header @s */}
                            <div className="nk-header nk-header-fixed is-light">
                                {/* Header */}
                                <Header />
                            </div>
                            {/* main header @e */}

                            {/* content @s */}
                            <div className="nk-content ">
                                <div className="container-fluid">
                                    <div className="nk-content-inner">
                                        <div className="nk-content-body">
                                            <div class="components-preview">
                                                <div className="nk-block-head nk-block-head-sm card p-4" style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', outline: 'none', marginTop: '20px' }}>
                                                    <div className="nk-block-between">
                                                        <div className="nk-block-head-content">
                                                            <h3 className="nk-block-title page-title" style={{ color: "#9d72ff" }}>Add Account</h3>
                                                        </div>{/* .nk-block-head-content */}
                                                        <div className="nk-block-head-content">
                                                            <Button className="btn btn-primary btn-sm d-none d-md-inline-flex" style={{ backgroundColor: "#9d72ff", border: "#9d72ff", outline: "none", boxShadow: "none" }} onClick={Navigate}><em className="icon ni ni-plus" /><span>Accounts List</span></Button>
                                                        </div>{/* .nk-block-head-content */}
                                                    </div>{/* .nk-block-between */}
                                                </div>{/* .nk-block-head */}
                                                <div className="nk-block nk-block-lg mt-5">
                                                    <div className="card card-preview" style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', outline: 'none', marginTop: '20px' }}>
                                                        <div className="card-inner">
                                                            <div className='row'>
                                                                <div className='col-6'>
                                                                    <label className="form-label" htmlFor="">Name</label>
                                                                    <span style={{ color: 'red', marginLeft: '2px' }}>*</span>
                                                                    <input type="text" className="form-control" name="name" placeholder="Enter Name" onChange={(e) => setName(e.target.value)} />
                                                                </div>
                                                                <div className='col-6'>
                                                                    <label className="form-label" htmlFor="">Type</label>
                                                                    <span style={{ color: 'red', marginLeft: '2px' }}>*</span>
                                                                    <input type="text" className="form-control" name="type" placeholder="Enter Type" onChange={(e) => setType(e.target.value)} />
                                                                </div>
                                                            </div>
                                                            <div className='row mt-3'>
                                                                <div className='col-6'>
                                                                    <label className="form-label" htmlFor="">Opening Balance</label>
                                                                    <span style={{ color: 'red', marginLeft: '2px' }}>*</span>
                                                                    <input type="number" className="form-control" name="opening_balance" placeholder="Enter Opening Balance" onChange={(e) => setOpeningBalance(e.target.value)} />
                                                                </div>
                                                                <div className='col-6'>
                                                                    <div className="custom-control custom-checkbox mt-4">
                                                                        <input type="checkbox" className="custom-control-input" id="default"
                                                                        name="defaults"  
                                                                        onChange={checkedDefault} />
                                                                        <label className="custom-control-label" htmlFor="default">
                                                                            <h4>Default</h4>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <hr />
                                                            <div className="form-group float-right">
                                                                <button type="button" className="btn btn-sm btn-danger mr-2">Cancel</button>
                                                                <button className="btn btn-sm btn-primary" style={{ backgroundColor: "#9d72ff", border: "#9d72ff", outline: "none", boxShadow: "none" }} onClick={AddData}>Add Product</button>
                                                            </div>
                                                        </div>
                                                    </div>{/* .card-preview */}
                                                </div> {/* nk-block */}
                                            </div>{/* .components-preview */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* content @e */}
                            {/* Footer */}
                            <div className="nk-footer">
                                <Footer />
                            </div>
                            {/* footer @e */}
                        </div>
                        {/* wrap @e */}
                    </div>
                    {/* main @e */}
                </div>
                {/* nk-app-root */}
            </div>
        </div>
    )
}
