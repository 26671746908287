import React, {useState, useEffect} from 'react'
import * as MyConstants from '../../../../Constant/Config'
import axios from 'axios'

export default function AddSubCategories({ closeModal, edit }) 
{
    const Outline = 
    {
        boxShadow: 'none',
        borderColor: '#9d72ff',
        backgroundColor: 'transparent'
    }

    // Get Categories Data 
    const [Categories, setCategories] = useState([]);

    useEffect(() => {
        fetch(MyConstants.ListCategories,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((result) => 
        {
            result.json().then((response) => 
            {
                console.warn(response);
                if(response.status == true)
                {
                    setCategories(response.categories);

                }
            })
        })
    }, []);

    

    const [ name, setName ] = useState("");
    const [ code, setCode ] = useState("");
    const [ parent_category, setParentCategory ] = useState("");
    const [ description, setDescription ] = useState("");
    const [image, setImage] = useState("");

    // AddData
    const AddData = (e) => 
    {
        const formData =  new FormData();
		formData.append('name',name);
        formData.append('code',code);
        formData.append('description',description);
        formData.append('parent_category',parent_category);
        formData.append('image',image);
		axios.post(MyConstants.addSubCategory,formData)
		.then((result)=>{
			if(result.data.status == true){
                closeModal(true);
                edit();
            }
		})
    }

    
    return (
        <div>
            <div className="form-group" >
                <label className="form-label" htmlFor="code">Ctaegory Code</label>
                <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                <div className="form-control-wrap">
                    <input type="text" className="form-control" name="code" placeholder="Enter Category Code" style={Outline} onChange={ (e)=>setCode(e.target.value) }  required />
                </div>
            </div>
            
            <div className="form-group" >
                <label className="form-label" htmlFor="name">Category Name</label>
                <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                <div className="form-control-wrap">
                    <input type="text" className="form-control" name="name" placeholder="Enter Category Name" style={Outline} onChange={ (e)=>setName(e.target.value) }  required />
                </div>
            </div>
            
            <div className="form-group" >
                <label className="form-label" htmlFor="ctgname">Parent Category</label>
                <div className="form-control-wrap">
                    <select className='form-control custom-select' value={parent_category} onChange={ (e)=>setParentCategory(e.target.value) }>
                    { Categories.map(item =>
                        <option value={item.id}>{item.name}</option>
                    )}
                    </select>
                </div>
            </div>
            
            <div className="form-group" >
                <label className="form-label" htmlFor="description">Description</label>
                <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                <div className="form-control-wrap">
                    <input type="text" className="form-control" name="description" placeholder="Enter Description" style={Outline} onChange={ (e)=>setDescription(e.target.value) }  required />
                </div>
            </div>

            <div className="form-group" >
                <label >Image</label>
                <input type="file"  name="image" onChange={(e)=>setImage(e.target.files[0])} style={{border: 'none', background: 'none'}} />
            </div>

            <div className="modal-footer bg-white"></div>
            <div className="form-group float-right">
            <button type="button" className="btn btn-sm btn-danger mr-2" onClick={closeModal}>Cancel</button>
                <button className="btn btn-sm btn-primary" style={{ backgroundColor: "#9d72ff", border: "#9d72ff", outline: "none", boxShadow: "none" }} onClick={AddData}>Add Sub Category</button>
            </div>
        </div>
    )
}
