import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
// import { multilanguage } from "redux-multilanguage";

const NavMenu = ({ strings, menuWhiteClass, sidebarMenu }) => {
  return (
    <div
      className={` ${
        sidebarMenu
          ? "sidebar-menu"
          : `main-menu ${menuWhiteClass ? menuWhiteClass : ""}`
      } `}
    >
      <nav>
        
      <ul>
        <li>
          <Link to={process.env.PUBLIC_URL + "/"}>
            Home
            {/* {strings["home"]} */}
            {sidebarMenu ? (
              <span>
                <i className="fa fa-angle-right"></i>
              </span>
            ) : (
              <i className="fa fa-angle-down" />
            )}
          </Link>
        </li>
        <li>
          <Link to={process.env.PUBLIC_URL + "/shop-grid-standard"}>
            {/* {" "}
            {strings["shop"]} */}
            Shop
            {sidebarMenu ? (
              <span>
                <i className="fa fa-angle-right"></i>
              </span>
            ) : (
              <i className="fa fa-angle-down" />
            )}
          </Link>
        </li>
        <li>
          <Link to={process.env.PUBLIC_URL + "/about"}>
            {/* {" "}
            {strings["about_us"]} */}
            About
            {sidebarMenu ? (
              <span>
                <i className="fa fa-angle-right"></i>
              </span>
            ) : (
              <i className="fa fa-angle-down" />
            )}
          </Link>
        </li>
        <li>
          <Link to={process.env.PUBLIC_URL + "/blog-standard"}>
            {/* {strings["blog"]} */}
            Blog
            {sidebarMenu ? (
              <span>
                <i className="fa fa-angle-right"></i>
              </span>
            ) : (
              <i className="fa fa-angle-down" />
            )}
          </Link>
        </li>
        <li>
          <Link to={process.env.PUBLIC_URL + "/contact"}>
            {/* {strings["contact_us"]} */}
          </Link>
        </li>
      </ul>
      </nav>
    </div>
  );
};

// NavMenu.propTypes = {
//   menuWhiteClass: PropTypes.string,
//   sidebarMenu: PropTypes.bool,
//   strings: PropTypes.object
// };

export default NavMenu;
// multilanguage(NavMenu);
