import Carts from '../carts/Carts';
import {connect} from 'react-redux';
import {addToCart,removeToCart,deleteCarts} from '../services/actions/index';

const mapStateToProps=state=>({
	cartItems:state
});

const mapDispatchToProps=dispatch=>({
	addToAllProductsHandler:data=>dispatch(addToCart(data)),
	removeToAllProductsHandler:data=>dispatch(removeToCart(data)),
	removeCartsHandler:()=>dispatch(deleteCarts()),
});

export default connect(mapStateToProps,mapDispatchToProps)(Carts);