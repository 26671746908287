import PropTypes from "prop-types";
// import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { connect } from "react-redux";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
// import RelatedProductSlider from "../../wrappers/product/RelatedProductSlider";
// import ProductDescriptionTab from "../../wrappers/product/ProductDescriptionTab";
// import ProductImageDescription from "../../wrappers/product/ProductImageDescription";
import React, { useState, useEffect, Fragment } from 'react'
import * as MyConstants from '../../Constant/Config'
import { Row, Col, Button, Table } from 'react-bootstrap'
import '../home/home.css'
import { useHistory } from "react-router-dom";
import { Spinner } from "react-bootstrap";



export default function Checkout(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [carts, setCarts] = useState([]);
    const [city, setCity] = useState([]);
    const [state, setState] = useState([]);
    const [deliveryAddress, setDeliveryAddress] = useState([]);

    const nav = useHistory();


    // useEffect(() => {
    //     if (JSON.parse(localStorage.getItem('carts'))) {
    // localStorage.setItem('carts', JSON.stringify(props.cartItems.cartData));
    //         setCarts(JSON.parse(localStorage.getItem('carts')));
    //     }
    // }, []);

    // const updateQnty = (data, e) => {
    //     console.log('data', data, 'quantity', e.target.value);
    //     props.addToAllProductsHandler({ id: data.id, quantity: e.target.value, price: data.price, sub_total: data.sub_total });
    //     setCarts(JSON.parse(localStorage.getItem('carts')));
    // }

    // const deleteCart = (data) => {
    //     props.removeToAllProductsHandler(data);
    //     setCarts(JSON.parse(localStorage.getItem('carts')));
    // }

    const [delivery_data,setDelivery_data] = useState(JSON.parse(localStorage.getItem('delivery_data')));

    const submitOrder = () => {
        // console.log(delivery_data);
        if (JSON.parse(localStorage.getItem('user-info'))) {
            let userinfo = JSON.parse(localStorage.getItem('user-info'));
            let data = {
                'delivery_data':delivery_data,
                'carts': JSON.parse(localStorage.getItem('carts')),
                'user_id': userinfo.id
            }
            setIsLoading(true);
            fetch(MyConstants.submitOrder,
                {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then((result) => {
                    result.json().then((response) => {
                        if (response.status == true) {
                            // console.log("JSON RESPONSE", response.message);
                            props.removeCartsHandler();
                            localStorage.removeItem('carts');
                            localStorage.removeItem('delivery_data');
                            setIsLoading(false);
                            nav.push('/');
                        }
                    })
                })
        } else {
            alert('Please login first');
        }
    }

    const updateDeliveryData = (e) =>{
        setDelivery_data({...delivery_data,[e.target.name]:e.target.value});
    }

    return (

        <Fragment>
            <MetaTags>
                <title>E-Mandi || Carts</title>
                <meta
                    name="description"
                    content="Product page of flone react minimalist eCommerce template."
                />
            </MetaTags>

            <BreadcrumbsItem to={process.env.PUBLIC_URL + "/"}>Home</BreadcrumbsItem>
            <BreadcrumbsItem to="/product-detail/">
                Shop Product
            </BreadcrumbsItem>

            <LayoutOne headerTop="visible">
                {/* breadcrumb */}
                <Breadcrumb />

                <div>
                    <div className="container">
                        <Row className="my-5">
                            <Col className="col-md-7">
                                <h2>Delivery Information</h2>
                                <div>
                                    Email Address<br></br>
                                    <input className='input' onChange={updateDeliveryData} type="text" name='email' placeholder='Enter Email Address'></input>
                                    {/* {/ <p className='p'>You can create Account after Checkout</p> /} */}
                                </div>
                                <br></br>
                                <div>
                                    First Name<br></br>
                                    <input className='input' type="text" onChange={updateDeliveryData} name='first_name' placeholder='Enter First Name'></input>
                                </div>
                                <br></br>
                                <div>
                                    Last Name<br></br>
                                    <input className='input' type="text" onChange={updateDeliveryData} name='last_name' placeholder='Enter Last Name'></input>
                                </div>
                                <br></br>
                                <div>
                                    Street Address<br></br>
                                    <textarea cols={10} rows={5} name="delivery_address" onChange={updateDeliveryData}></textarea>
                                </div>
                                <br></br>
                                <div>
                                    Phone Number<br></br>
                                    <input className="input" type="text" onChange={updateDeliveryData} name='phone' placeholder='Enter Phone Number'></input>
                                </div>
                                <button onClick={submitOrder} className="btn btn-info mt-3">Submit</button>
                                {/* <p className='c'>Shipping Methods</p>
                                <hr></hr>
                                <div>
                                    <input type="radio" />
                                    <label className='r'>PKR2,3790.00</label>
                                    <label className='r2'>International Shipping</label>

                                </div> */}
                            </Col>
                            <Col className="col-md-5">

                            </Col>
                        </Row>
                        {isLoading ? <Spinner style={{ marginLeft: "150px" }} animation={'border'} /> : ''}
                    </div>
                </div>

            </LayoutOne>
        </Fragment>
    );
};
