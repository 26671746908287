import PropTypes from "prop-types";
// import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { connect } from "react-redux";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import RelatedProductSlider from "../../wrappers/product/RelatedProductSlider";
import ProductDescriptionTab from "../../wrappers/product/ProductDescriptionTab";
import ProductImageDescription from "../../wrappers/product/ProductImageDescription";
import React, { useState, useEffect, Fragment } from 'react'
import * as MyConstants from '../../Constant/Config'
import { Row, Col, Button } from 'react-bootstrap'
import '../home/home.css'
import { useHistory } from "react-router-dom";
import { FaTruck } from 'react-icons/fa'


export default function ProductDetail(props) {

    const url = window.location.pathname;
    const split = url.split('/');
    const [ProductDetail, setProductDetail] = useState([]);
    const [cart, setCart] = useState([]);
    const [quantity, setQuantity] = useState('1');
    const history = useHistory();

    useEffect(() => {
        fetch(MyConstants.FrontEndProductDetail + split[2],
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((result) => {
                result.json().then((response) => {
                    if (response.status == true) {
                        setProductDetail(response.product[0]);
                    }
                })
            })
    }, []);

    const addCart = (cartData) => {
        props.addToAllProductsHandler(cartData);
        alert('Cart added successfully!');
        history.push('/product-detail/'+split[2]);
        // history.push('/carts/');
    }

    return (

        <Fragment>
            <MetaTags>
                <title>E-Mandi || Product Details</title>
                <meta
                    name="description"
                    content="Product page of flone react minimalist eCommerce template."
                />
            </MetaTags>

            <BreadcrumbsItem to={process.env.PUBLIC_URL + "/"}>Home</BreadcrumbsItem>
            <BreadcrumbsItem to="/product-detail/">
                Shop Product
            </BreadcrumbsItem>

            <LayoutOne headerTop="visible">
                {/* breadcrumb */}
                <Breadcrumb />

                <div>
                    <div className="container">
                        <Row className="my-5">
                            {/* <Col>
                                <img class="img-fluid w-75 h-75" src={MyConstants.ImageUrl + ProductDetail.image} />
                            </Col>
                            <Col>
                                <h3 class="pt-4 pb-2 font-weight-bold">{ProductDetail.name}</h3>
                                <h3 class="font-weight-bold text-danger"><span className="mr-2">{ProductDetail.currency_symbol}</span>{ProductDetail.price}</h3>
                                <p className="my-4 text-justify">{ProductDetail.detail}</p>
                                <hr />
                                <Row>
                                    <Col>
                                        <h4>Color</h4>
                                        <input type="radio" style={{height: '25px', width: '25px', background: '#000'}}  id="black" name="redio" value="black"/>
                                        <label for="black" style={{display: 'none'}} ></label>

                                        <input type="radio" style={{height: '25px', width: '25px', marginLeft: '5px'}}  id="blue" name="redio" value="blue"/>
                                        <label for="blue" style={{display: 'none'}} ></label>

                                        <input type="radio" style={{height: '25px', width: '25px', marginLeft: '5px'}}  id="red" name="redio" value="red"/>
                                        <label for="red" style={{display: 'none'}} ></label>
                                    </Col>
                                    <Col>
                                        <h4>Size</h4>
                                        <select class="custom-select w-50">
                                            <option>Select Size</option>
                                            <option>XL</option>
                                            <option>XXL</option>
                                            <option>Large</option>
                                            <option>Medium</option>
                                            <option>Small</option>
                                        </select>
                                    </Col>
                                </Row>
                                <input type="number" placeholder="Quantity" min={1} defaultValue={quantity} onChange={(e)=> setQuantity(e.target.value)} className="w-25 form-control float-left mt-3 mr-2" />
                                <Button className="mt-3" variant="dark" onClick={()=>addCart({'id':ProductDetail.id,'name':ProductDetail.name,'quantity':quantity,'price':ProductDetail.price,'sub_total':(ProductDetail.price*quantity)})}>Add to cart</Button>
                            </Col> */}


                            <Col>
                                <img class="img-fluid w-75 h-75" src={MyConstants.ImageUrl + ProductDetail.image} />
                            </Col>

                            <Col>
                                <h3 class="pt-4 font-weight-bold">{ProductDetail.name}</h3>
                                <h5 className="text-uppercase text-secondary">in stock</h5>
                                <h4 class="font-weight-medium text-dark"><span className="mr-2">{ProductDetail.currency_symbol}</span>{ProductDetail.price}</h4>
                                <div className="text-uppercase text-light bg-danger w-25 py-2 px-3">few items left</div>
                                <input type="number" placeholder="Quantity" min={1} defaultValue={quantity} onChange={(e)=> setQuantity(e.target.value)} className="w-25 form-control float-left mt-3 mr-2" />
                                {/* <Button className="mt-3" variant="dark" >Add to cart</Button> */}
                                <Button className="mt-3 w-75 text-capitalize font-weight-bold" variant="dark" onClick={()=>addCart({'id':ProductDetail.id,'name':ProductDetail.name,'quantity':quantity,'price':ProductDetail.price,'sub_total':(ProductDetail.price*quantity)})}>add to cart</Button>

                                <hr />
                                <p className="my-4 text-justify">{ProductDetail.detail}</p>
                                <hr />

                                <div className="bg-light p-3">
                                    <h4 className="text-capitalize"><FaTruck className="mr-3" />free shipping policy</h4>
                                    <h4 className="text-capitalize mt-2 font-weight-bold">in pakistan</h4>
                                    <p>- Free shipping on all prepaid orders.</p>
                                    <p>- For COD orders, free shipping on all orders above PKR 2000.</p>

                                    <h4 className="text-capitalize mt-2 font-weight-bold">international</h4>
                                    <p>- Free shipping on all orders above USD 400 during non-sale period.</p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>

            </LayoutOne>
        </Fragment>
    );
};
