import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export default function SkeletonProductDetail() {
    return (
            <div className='product-wrap'>
                <div className="product-img">
                    <Skeleton height={300} coint={1} />
                </div>
                <div className="product-content">
                    <Skeleton coint={1} />
                    <Skeleton coint={1} />
                </div>
            </div>
    );
};