import App from '../../App';
import {connect} from 'react-redux';
import {addToCart,removeToCart} from '../services/actions/index';

const mapStateToProps=state=>({
	cartItems:state
});

const mapDispatchToProps=dispatch=>({
	addToAllProductsHandler:data=>dispatch(addToCart(data)),
	removeToAllProductsHandler:data=>dispatch(removeToCart(data))
});

export default connect(mapStateToProps,mapDispatchToProps)(App);