import React, { useState, useEffect } from 'react'
import Sidebar from '../../Sidebar'
// Header
import Header from '../../Header'
// Footer
import Footer from '../../Footer'
import { Link } from 'react-router-dom'
import {Modal, Button} from 'react-bootstrap'
import AddUnits from './AddUnits'
import EditUnits from './EditUnits'
import * as MyConstants from '../../../../Constant/Config' 
 
export default function Units() 
{
    // Get Units Data 
    const [Units, setUnits] = useState([]);

    useEffect(() => {
        fetch(MyConstants.listUnit,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((result) => 
            {
                result.json().then((response) => 
                {
                    console.warn(response);
                    if(response.status == true)
                    {
                        setUnits(response.units);
                    }
                })
            })
    },[]);
    console.warn(Units);

    // Edit Units Data
    const [EditModalShow, setEditModalShow] = useState(false);
    const [EditUnitsModalData, setEditUnitsModalData] = useState([]);

    const EditUnitsModalClose = () => setEditModalShow(false);
    const EditUnitsModalShow = () => setEditModalShow(true);

    const GetUnitsData = (id, code, name) =>
    {
        let EditUnitsModalData = [ id, code, name ];
        setEditUnitsModalData([...EditUnitsModalData]);
        return setEditModalShow(true);
    }

    // Add Units Data
    const [AddModalShow, setAddModalShow] = useState(false);
    const [AddUnitsModalData, setAddUnitsModalData] = useState([]);

    const AddUnitsModalClose = () => setAddModalShow(false);
    const AddUnitsModalShow = () => setAddModalShow(true);

    const AddUnitsData = () =>
    {
        setAddUnitsModalData([...AddUnitsModalData]);
        return setAddModalShow(true);
    }

    const edit  = () =>{
        fetch(MyConstants.listUnit,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((result) => 
        {
            result.json().then((response) => 
            {
                console.warn(response);
                if(response.status == true)
                {
                    setUnits(response.units);
                }
            })
        })
    }
    

    // Delete Data
    const DeleteData = (id) => 
    {
        fetch(MyConstants.deleteUnit + id,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((result) => 
        {
            result.json().then((response) => 
            {
                console.warn(response);
                if(response.status == true)
                {
                    edit();
                }
            })
        })
    }

    return (
        <div>
            <div className="nk-app-root">
                {/* main @s */}
                <div className="nk-main ">
                    {/* sidebar @s */}
                    <div className="nk-sidebar nk-sidebar-fixed is-light " data-content="sidebarMenu">
                        <Sidebar />
                    </div>
                    {/* Sidebar @e */}
                    {/* wrap @s */}
                    <div className="nk-wrap ">
                        {/* main header @s */}
                        <div className="nk-header nk-header-fixed is-light">
                            {/* Header */}
                            <Header />
                        </div>
                        {/* main header @e */}

                        {/* content @s */}
                        <div className="nk-content ">
                            <div className="container-fluid">
                                <div className="nk-content-inner">
                                    <div className="nk-content-body">
                                        <div class="components-preview">
                                            <div className="nk-block-head nk-block-head-sm card p-4" style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', outline: 'none', marginTop: '20px' }}>
                                                <div className="nk-block-between">
                                                    <div className="nk-block-head-content">
                                                        <h3 className="nk-block-title page-title" style={{ color: "#9d72ff" }}>Units</h3>
                                                    </div>{/* .nk-block-head-content */}
                                                    <div className="nk-block-head-content">
                                                        <Button to="/units" className="btn btn-primary btn-sm d-none d-md-inline-flex" style={{ backgroundColor: "#9d72ff", border: "#9d72ff", outline: "none", boxShadow: "none" }} onClick={ AddUnitsData } ><em className="icon ni ni-plus"/><span>Add Units</span></Button>
                                                    </div>{/* .nk-block-head-content */}
                                                </div>{/* .nk-block-between */}
                                            </div>{/* .nk-block-head */}
                                            <div className="nk-block nk-block-lg mt-5">
                                                <div className="card card-preview" style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', outline: 'none', marginTop: '20px' }}>
                                                    <div className="card-inner">
                                                        <table className="table table-bordered table-hover table-striped table-responsive-sm">
                                                            <thead>
                                                                <tr>
                                                                    <th>Sr#</th>
                                                                    <th>Unit Code</th>
                                                                    <th>Unit Name</th>
                                                                    <th>Actions</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    Units.map(item =>
                                                                    <tr>
                                                                        <td>{item.id}</td>
                                                                        <td>{item.code}</td>
                                                                        <td>{item.name}</td>
                                                                        <td>
                                                                        <Button className="btn btn-primary d-none  btn-sm d-md-inline-flex" style={{ backgroundColor: "#9d72ff", border: "#9d72ff", outline: "none", boxShadow: "none" }} onClick={() => GetUnitsData(item.id, item.code, item.name)}><em className="icon ni ni-edit" /><span>Edit</span></Button>
                                                                        <Button className="btn btn-danger btn-sm ml-2 d-md-inline-flex"  onClick={() => DeleteData(item.id)}><em className="icon ni ni-trash" /><span>Delete</span></Button>
                                                                    </td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>{/* .card-preview */}
                                            </div> {/* nk-block */}

                                        </div>{/* .components-preview */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* content @e */}
                        {/* Footer */}
                        <div className="nk-footer">
                            <Footer />
                        </div>
                        {/* footer @e */}
                    </div>
                    {/* wrap @e */}
                </div>
                {/* main @e */}
            </div>
            {/* nk-app-root */}

            {/* EditUnits Modal */}
            {
                EditModalShow === true ? 
                    <Modal className="fade zoom" show={EditUnitsModalShow} onHide={EditUnitsModalClose} backdrop="static">
                        <Modal.Header>
                            <Modal.Title>Edit Units</Modal.Title>
                            <a href="#" className="close" onClick={EditUnitsModalClose}>
                                <em className="icon ni ni-cross" />
                            </a>
                        </Modal.Header>
                        <Modal.Body> 
                            <p>Please fill in the information below. The field labels marked with * are required input fields.</p>
                            {/* Edit Units */}
                            <EditUnits closeModal={EditUnitsModalClose} id={EditUnitsModalData[0]} code={EditUnitsModalData[1]} name={EditUnitsModalData[2]} edit={edit} />
                        </Modal.Body>
                    </Modal>
                : ''
            }
            
            {/* AddUnitsModal */}
            {
                AddModalShow === true ? 
                    <Modal className="fade zoom" show={AddUnitsModalShow} onHide={AddUnitsModalClose} backdrop="static">
                        <Modal.Header>
                            <Modal.Title>Add Unit</Modal.Title>
                            <a href="#" className="close" onClick={AddUnitsModalClose}>
                                <em className="icon ni ni-cross" />
                            </a>
                        </Modal.Header>
                        <Modal.Body> 
                            <p>Please fill in the information below. The field labels marked with * are required input fields.</p>
                            {/* Add Units */}
                            <AddUnits closeModal={AddUnitsModalClose} edit={edit} />
                        </Modal.Body>
                    </Modal>
                : ''
            }

        </div>
    )
}
