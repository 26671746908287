import React from 'react'
import Sidebar from '../../Sidebar'
import Header from '../../Header'
import Footer from '../../Footer'
import { Row, Col, Card, Button } from 'react-bootstrap'
import {Link } from 'react-router-dom'
import {FaDollarSign,FaTruck} from 'react-icons/fa'
import {BsFillCheckCircleFill} from 'react-icons/bs'

export default function Dashview() {
    return (
        <div>
            <div className="nk-app-root">
                {/* main @s */}
                <div className="nk-main ">
                    {/* sidebar @s */}
                    <div className="nk-sidebar nk-sidebar-fixed is-light " data-content="sidebarMenu">
                        <Sidebar />
                    </div>
                    {/* Sidebar @e */}
                    {/* wrap @s */}
                    <div className="nk-wrap ">
                        {/* main header @s */}
                        <div className="nk-header nk-header-fixed is-light">
                            {/* Header */}
                            <Header />
                        </div>
                        {/* main header @e */}

                        {/* content @s */}
                        <div className="nk-content ">
                            <div className="container-fluid">
                                <div className="nk-content-inner">
                                    <div className="nk-content-body">
                                        <div className="nk-block-head nk-block-head-sm">
                                            <div className="nk-block-between mt-4">
                                                <div className="nk-block-head-content">
                                                    <h3 className="nk-block-title page-title">Dashboard</h3>
                                                </div>{/* .nk-block-head-content */}
                                                <div className="nk-block-head-content">
                                                    <div className="toggle-wrap nk-block-tools-toggle">
                                                        <a href="#" className="btn btn-icon btn-trigger toggle-expand mr-n1" data-target="pageMenu"><em className="icon ni ni-more-v" /></a>
                                                        <div className="toggle-expand-content" data-content="pageMenu">
                                                            <ul className="nk-block-tools g-3">
                                                                <li>
                                                                    <div className="drodown">
                                                                        <a href="#" className="dropdown-toggle btn btn-white btn-dim btn-outline-light" data-toggle="dropdown"><em className="d-none d-sm-inline icon ni ni-calender-date" /><span><span className="d-none d-md-inline">Last</span> 30 Days</span><em className="dd-indc icon ni ni-chevron-right" /></a>
                                                                        <div className="dropdown-menu dropdown-menu-right">
                                                                            <ul className="link-list-opt no-bdr">
                                                                                <li><a href="#"><span>Last 30 Days</span></a></li>
                                                                                <li><a href="#"><span>Last 6 Months</span></a></li>
                                                                                <li><a href="#"><span>Last 1 Years</span></a></li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li className="nk-block-tools-opt"><a href="#" className="btn btn-primary"><em className="icon ni ni-reports" /><span>Reports</span></a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>{/* .nk-block-head-content */}
                                            </div>{/* .nk-block-between */}
                                        </div>{/* .nk-block-head */}
                                        <div className="nk-block">
                                            <Row>
                                                <Col lg={4} md={6} sm={12}>
                                                    <Card style={{boxShadow: 'none'}}>
                                                        <Card.Body>
                                                            <Row>
                                                                <Col md={6} sm={12}>
                                                                    <h5 className='font-weight-bold'>Orders Pending!</h5>
                                                                    <div className="my-3" style={{color: '#364a63', fontWeight: 'bold', fontSize: '2rem'}}>3</div>
                                                                </Col>
                                                                <Col md={6} sm={12} className='my-sm-3 my-md-0 d-md-flex align-items-center justify-content-center'>
                                                                    <span style={{fontSize: '30px'}} className="text-danger"><FaDollarSign /></span>
                                                                </Col>
                                                            </Row>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                <Col lg={4} md={6} sm={12}>
                                                    <Card style={{boxShadow: 'none'}}>
                                                        <Card.Body>
                                                            <Row>
                                                                <Col md={6} sm={12}>
                                                                    <h5 className='font-weight-bold'>Orders Commission!</h5>
                                                                    <div className="my-3" style={{color: '#364a63', fontWeight: 'bold', fontSize: '2rem'}}>0</div>
                                                                </Col>
                                                                <Col md={6} sm={12} className='my-sm-3 my-md-0 d-md-flex align-items-center justify-content-center'>
                                                                    <span style={{fontSize: '30px'}} className="text-danger"><FaTruck /></span>
                                                                </Col>
                                                            </Row>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                <Col lg={4} md={6} sm={12}>
                                                    <Card style={{boxShadow: 'none'}}>
                                                        <Card.Body>
                                                            <Row>
                                                                <Col md={6} sm={12}>
                                                                    <h5 className='font-weight-bold'>Orders Completed!</h5>
                                                                    <div className="my-3" style={{color: '#364a63', fontWeight: 'bold', fontSize: '2rem'}}>3</div>
                                                                </Col>
                                                                <Col md={6} sm={12} className='my-sm-3 my-md-0 d-md-flex align-items-center justify-content-center'>
                                                                    <span style={{fontSize: '30px'}} className="text-danger"><BsFillCheckCircleFill /></span>
                                                                </Col>
                                                            </Row>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            </Row>
                                            <Row className='mt-3'>
                                                <Col lg={4} md={6} sm={12}>
                                                    <Card style={{boxShadow: 'none'}}>
                                                        <Card.Body>
                                                            <Row>
                                                                <Col md={6} sm={12}>
                                                                    <h5 className='font-weight-bold'>Total Products!</h5>
                                                                    <div className="my-3" style={{color: '#364a63', fontWeight: 'bold', fontSize: '2rem'}}>56</div>
                                                                </Col>
                                                                <Col md={6} sm={12} className='my-sm-3 my-md-0 d-md-flex align-items-center justify-content-center'>
                                                                    <span style={{fontSize: '30px'}} className="text-danger"><FaDollarSign /></span>
                                                                </Col>
                                                            </Row>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                <Col lg={4} md={6} sm={12}>
                                                    <Card style={{boxShadow: 'none'}}>
                                                        <Card.Body>
                                                            <Row>
                                                                <Col md={6} sm={12}>
                                                                    <h5 className='font-weight-bold'>Total Customers!</h5>
                                                                    <div className="my-3" style={{color: '#364a63', fontWeight: 'bold', fontSize: '2rem'}}>11</div>
                                                                </Col>
                                                                <Col md={6} sm={12} className='my-sm-3 my-md-0 d-md-flex align-items-center justify-content-center'>
                                                                    <span style={{fontSize: '30px'}} className="text-danger"><FaTruck /></span>
                                                                </Col>
                                                            </Row>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                <Col lg={4} md={6} sm={12}>
                                                    <Card style={{boxShadow: 'none'}}>
                                                        <Card.Body>
                                                            <Row>
                                                                <Col md={6} sm={12}>
                                                                    <h5 className='font-weight-bold'>Total Posts!</h5>
                                                                    <div className="my-3" style={{color: '#364a63', fontWeight: 'bold', fontSize: '2rem'}}>30</div>
                                                                </Col>
                                                                <Col md={6} sm={12} className='my-sm-3 my-md-0 d-md-flex align-items-center justify-content-center'>
                                                                    <span style={{fontSize: '30px'}} className="text-danger"><BsFillCheckCircleFill /></span>
                                                                </Col>
                                                            </Row>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            </Row>
                                            <Row className='mt-3'>
                                                <Col lg={4} md={6} sm={12}>
                                                    <Card style={{boxShadow: 'none'}}>
                                                        <Card.Body>
                                                            <Row>
                                                                <Col md={6} sm={12}>
                                                                    <h4 className='font-weight-bold my-2'>Today's Booking!</h4>
                                                                    <h5 className="my-0 font-weight-bold text-success">Received: <span style={{fontSize: '22px'}} className="font-weight-normal text-success">0</span></h5>
                                                                    <h5 className="my-0 font-weight-bold text-danger">Receivable: <span style={{fontSize: '22px'}} className="font-weight-normal text-danger">0</span></h5>
                                                                </Col>
                                                                <Col md={6} sm={12} className='my-sm-3 my-md-0 d-md-flex align-items-center justify-content-center'>
                                                                </Col>
                                                            </Row>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                <Col lg={4} md={6} sm={12}>
                                                    <Card style={{boxShadow: 'none'}}>
                                                        <Card.Body>
                                                            <Row>
                                                                <Col md={6} sm={12}>
                                                                    <h4 className='font-weight-bold my-2'>Last 30 Day's!</h4>
                                                                    <h5 className="my-0 font-weight-bold text-success">Received: <span style={{fontSize: '22px'}} className="font-weight-normal text-success">0</span></h5>
                                                                    <h5 className="my-0 font-weight-bold text-danger">Receivable: <span style={{fontSize: '22px'}} className="font-weight-normal text-danger">0</span></h5>
                                                                </Col>
                                                                <Col md={6} sm={12} className='my-sm-3 my-md-0 d-md-flex align-items-center justify-content-center'>
                                                                </Col>
                                                            </Row>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                <Col lg={4} md={6} sm={12}>
                                                    <Card style={{boxShadow: 'none'}}>
                                                        <Card.Body>
                                                            <Row>
                                                                <Col md={6} sm={12}>
                                                                    <h4 className='font-weight-bold my-2'>Last 30 Day's!</h4>
                                                                    <h5 className="my-0 font-weight-bold text-success">Paid: <span style={{fontSize: '22px'}} className="font-weight-normal text-success">0</span></h5>
                                                                    <h5 className="my-0 font-weight-bold text-danger">Payable: <span style={{fontSize: '22px'}} className="font-weight-normal text-danger">0</span></h5>
                                                                </Col>
                                                                <Col md={6} sm={12} className='my-sm-3 my-md-0 d-md-flex align-items-center justify-content-center'>
                                                                </Col>
                                                            </Row>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={6} sm={12}>
                                                    <div className="card card-preview" style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', outline: 'none', marginTop: '20px' }}>
                                                        <div className="card-header">
                                                            <h4 className="py-2 page-title" style={{ color: "#9d72ff" }}>Recent Orders</h4>
                                                        </div>
                                                        <div className='card-body'>
                                                            <div className="row">
                                                                <div className="col-4">
                                                                    <h4 className='font-weight-bold'>Order Number</h4>
                                                                </div>
                                                                <div className="col-4">
                                                                    <h4 className='font-weight-bold'>Order Date</h4>
                                                                </div>
                                                            </div>
                                                            <div className="row mt-3">
                                                                <div className="col-4">
                                                                    <h5>0123456789</h5>
                                                                </div>
                                                                <div className="col-4">
                                                                    <h5>27th Dec, 2021</h5>
                                                                </div>
                                                                <div className="col-4">
                                                                    <Link to="/orders-detail" className="btn btn-primary d-none  btn-sm d-md-inline-flex mr-2" style={{ backgroundColor: "#9d72ff", border: "#9d72ff", outline: "none", boxShadow: "none" }}><em className="icon ni ni-eye" /><span>Details</span></Link>
                                                                </div>
                                                            </div>
                                                            <div className="row mt-3">
                                                                <div className="col-4">
                                                                    <h5>0123456789</h5>
                                                                </div>
                                                                <div className="col-4">
                                                                    <h5>27th Dec, 2021</h5>
                                                                </div>
                                                                <div className="col-4">
                                                                    <Link to="/orders-detail" className="btn btn-primary d-none  btn-sm d-md-inline-flex mr-2" style={{ backgroundColor: "#9d72ff", border: "#9d72ff", outline: "none", boxShadow: "none" }}><em className="icon ni ni-eye" /><span>Details</span></Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col lg={6} sm={12}>
                                                    <div className="card card-preview" style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', outline: 'none', marginTop: '20px' }}>
                                                        <div className="card-header">
                                                            <h4 className="py-2 page-title" style={{ color: "#9d72ff" }}>Recent Customers</h4>
                                                        </div>
                                                        <div className='card-body'>
                                                            <div className="row">
                                                                <div className="col-4">
                                                                    <h4 className='font-weight-bold'>Customers Email</h4>
                                                                </div>
                                                                <div className="col-4">
                                                                    <h4 className='font-weight-bold'>Joined Date</h4>
                                                                </div>
                                                            </div>
                                                            <div className="row mt-3">
                                                                <div className="col-4">
                                                                    <h5>info@gmail.com</h5>
                                                                </div>
                                                                <div className="col-4">
                                                                    <h5>27th Dec, 2021</h5>
                                                                </div>
                                                                <div className="col-4">
                                                                    <Link to="/orders-detail" className="btn btn-primary d-none  btn-sm d-md-inline-flex mr-2" style={{ backgroundColor: "#9d72ff", border: "#9d72ff", outline: "none", boxShadow: "none" }}><em className="icon ni ni-eye" /><span>Details</span></Link>
                                                                </div>
                                                            </div>
                                                            <div className="row mt-3">
                                                                <div className="col-4">
                                                                    <h5>info@gmail.com</h5>
                                                                </div>
                                                                <div className="col-4">
                                                                    <h5>27th Dec, 2021</h5>
                                                                </div>
                                                                <div className="col-4">
                                                                    <Link to="/orders-detail" className="btn btn-primary d-none  btn-sm d-md-inline-flex mr-2" style={{ backgroundColor: "#9d72ff", border: "#9d72ff", outline: "none", boxShadow: "none" }}><em className="icon ni ni-eye" /><span>Details</span></Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>{/* .nk-block */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* content @e */}
                        {/* Footer */}
                        <div className="nk-footer">
                            <Footer />
                        </div>
                        {/* footer @e */}
                    </div>
                    {/* wrap @e */}
                </div>
                {/* main @e */}
            </div>
            {/* nk-app-root */}
        </div>
    )
}
