import React, { useState, useEffect } from 'react'
import Sidebar from '../../Sidebar'
// Header
import Header from '../../Header'
// Footer
import Footer from '../../Footer'
import { Row, Col, Button } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom';
import * as MyConstants from '../../../../Constant/Config'
// import swal from 'sweetalert'

// StatusStyle
const StatusStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '30px',
    width: '30%',
    borderRadius: '10px',
    backgroundColor: '#dff7fb',
    color: '#13c9f2',
    fontWeight: '600'
}

// Image
const Image =
{
    height: '100px',
    width: '100px',
    borderRadius: '10px'
}

// StatusPending
const StatusPending = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '30px',
    width: '50%',
    margin: '12px 0 0 12px',
    borderRadius: '10px',
    backgroundColor: '#dff7fb',
    color: '#13c9f2',
    fontWeight: '600'
}

export default function OrdersDetail() {

    const url = window.location.pathname;
    const split = url.split('/');
    console.log(split[2]);

    // Edit
    const History = useHistory();
    const NavigateTo = () => History.push('/list-orders');
    // const Navigate = () => History.push('/edit-product/');

    const [userOrderDetail, setUserOrderDetail] = useState('');
    const [orderProducts, setOrderProducts] = useState('');
    const [total, setTotal] = useState('');

    useEffect(() => {

        // EditProducts
        fetch(MyConstants.showOrderDetail + split[2],
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((result) => {
                result.json().then((response) => {
                    console.warn(response);
                    if (response.status == true) {
                        setUserOrderDetail(response.user_order_detail[0]);
                        setOrderProducts(response.ordered_products);
                        setTotal(response.total[0]);
                    }
                })
            });
    }, []);
    console.log('orderProducts', orderProducts);

    const updatePaymentStatus = (e) => {
        let data = { status: e.target.value, order_id: split[2] };
        fetch(MyConstants.updatePaymentStatus,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data),
            }).then((result) => {
                result.json().then((response) => {
                    console.warn(response);
                    if (response.status == true) {
                        setUserOrderDetail(response.user_order_detail[0]);
                        setOrderProducts(response.ordered_products);
                        setTotal(response.total[0]);
                    }
                })
            });
    }

    return (
        <div>
            <div className="nk-app-root">
                {/* main @s */}
                <div className="nk-main ">
                    {/* sidebar @s */}
                    <div className="nk-sidebar nk-sidebar-fixed is-light " data-content="sidebarMenu">
                        <Sidebar />
                    </div>
                    {/* Sidebar @e */}
                    {/* wrap @s */}
                    <div className="nk-wrap ">
                        {/* main header @s */}
                        <div className="nk-header nk-header-fixed is-light">
                            {/* Header */}
                            <Header />
                        </div>
                        {/* main header @e */}

                        {/* content @s */}
                        <div className="nk-content ">
                            <div className="container-fluid">
                                <div className="nk-content-inner">
                                    <div className="nk-content-body">
                                        <div class="components-preview">
                                            <div className="nk-block-head nk-block-head-sm card p-4" style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', outline: 'none', marginTop: '20px' }}>
                                                <div className="nk-block-between">
                                                    <div className="nk-block-head-content">
                                                        <h3 className="nk-block-title page-title" style={{ color: "#9d72ff" }}>Orders Detail</h3>
                                                    </div>{/* .nk-block-head-content */}
                                                    <div className="nk-block-head-content">
                                                        <Button className="btn btn-primary btn-sm d-none d-md-inline-flex" style={{ backgroundColor: "#9d72ff", border: "#9d72ff", outline: "none", boxShadow: "none" }} onClick={NavigateTo} ><em className="icon ni ni-plus" /><span>Orders List</span></Button>
                                                    </div>{/* .nk-block-head-content */}
                                                </div>{/* .nk-block-between */}
                                            </div>{/* .nk-block-head */}
                                            <div className="nk-block nk-block-lg mt-5 ml-0">
                                                <div className='row'>
                                                    <div className='col-lg-6 col-12'>
                                                        <div className="card card-preview" style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', outline: 'none', marginTop: '20px' }}>
                                                            <div className="card-header">
                                                                <h4 className="py-2 page-title" style={{ color: "#9d72ff" }}>Order Detail</h4>
                                                            </div>
                                                            <div className='card-body'>
                                                                <div className="row mt-4">
                                                                    <div className="col-6">
                                                                        <h4>Name</h4>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <h5>{userOrderDetail.first_name} {userOrderDetail.last_name}</h5>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-2">
                                                                    <div className="col-6">
                                                                        <h4>Email</h4>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <h5>{userOrderDetail.order_email}</h5>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-2">
                                                                    <div className="col-6">
                                                                        <h4>Phone</h4>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <h5>{userOrderDetail.order_phone}</h5>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-2">
                                                                    <div className="col-6">
                                                                        <h4>City</h4>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <h5>{userOrderDetail.cityName}</h5>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-2">
                                                                    <div className="col-6">
                                                                        <h4>State/Province</h4>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <h5>{userOrderDetail.stateProvinceName}</h5>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-2">
                                                                    <div className="col-6">
                                                                        <h4>Delivery Address</h4>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <h5>{userOrderDetail.delivery_address}</h5>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-2">
                                                                    <div className="col-6">
                                                                        <h4>Order Status</h4>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <select className='form-control custom-select w-75' name="order_status">
                                                                            {(userOrderDetail.order_status == 'pending') ? (<option selected>pending</option>) : (<option>pending</option>)}

                                                                            {(userOrderDetail.order_status == 'delivered') ? (<option selected>delivered</option>) : (<option>delivered</option>)}

                                                                            {(userOrderDetail.order_status == 'completed') ? (<option selected>completed</option>) : (<option>completed</option>)}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-2">
                                                                    <div className="col-6">
                                                                        <h4>Payment Status</h4>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <select className='form-control custom-select w-75' onChange={updatePaymentStatus} name="order_status">
                                                                            {(userOrderDetail.payment_status == 'paid') ? (<option selected>paid</option>) : (<option>paid</option>)}

                                                                            {(userOrderDetail.payment_status == 'unpaid') ? (<option selected>unpaid</option>) : (<option>unpaid</option>)}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='card-footer'>
                                                                <Link to="/orders-detail" className="btn btn-primary d-none  btn-sm d-md-inline-flex mr-2" style={{ backgroundColor: "#9d72ff", border: "#9d72ff", outline: "none", boxShadow: "none" }}><em className="icon ni ni-eye" /><span>View Invoice</span></Link>
                                                            </div>
                                                        </div>{/* .card-preview */}
                                                    </div>
                                                    <div className='col-lg-6 col-12'>
                                                        <div className="card card-preview" style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', outline: 'none', marginTop: '20px' }}>
                                                            <div className="card-header">
                                                                <h4 className="py-2 page-title" style={{ color: "#9d72ff" }}>Users Detail<Link to="/orders-detail" style={{ marginLeft: '10px', fontSize: '20px' }}><em className="icon ni ni-edit" />Edit</Link></h4>
                                                            </div>
                                                            <div className='card-body'>
                                                                <div className="row mt-4">
                                                                    <div className="col-6">
                                                                        <h4>Name</h4>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <h5>{userOrderDetail.name}</h5>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-2">
                                                                    <div className="col-6">
                                                                        <h4>Email</h4>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <h5>{userOrderDetail.email}</h5>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-2">
                                                                    <div className="col-6">
                                                                        <h4>Phone</h4>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <h5>{userOrderDetail.phone}</h5>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-2">
                                                                    <div className="col-6">
                                                                        <h4>Address</h4>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <h5>{userOrderDetail.address}</h5>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-2">
                                                                    <div className="col-6">
                                                                        <h4>State/Province</h4>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <h5>{userOrderDetail.stateProvinceName}</h5>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-2">
                                                                    <div className="col-6">
                                                                        <h4>Gender</h4>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <h5>{userOrderDetail.gender}</h5>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-2">
                                                                    <div className="col-6">
                                                                        <h4>City</h4>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <h5>{userOrderDetail.cityName}</h5>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-2">
                                                                    <div className="col-6">
                                                                        <h4>Role</h4>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <h5>{userOrderDetail.role}</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>{/* .card-preview */}
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-12'>
                                                        <div className="card card-preview" style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', outline: 'none', marginTop: '20px' }}>
                                                            <div className="card-header">
                                                                <h4 className="py-2 page-title" style={{ color: "#9d72ff" }}>Shipping Details<Link to="/orders-detail" style={{ marginLeft: '10px', fontSize: '20px' }}><em className="icon ni ni-edit" />Edit</Link></h4>
                                                            </div>
                                                            <div className='card-body'>
                                                                <div className="row mt-4">
                                                                    <div className="col-6">
                                                                        <h4>Name</h4>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <h5>User</h5>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-2">
                                                                    <div className="col-6">
                                                                        <h4>Email</h4>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <h5>user@gmail.com</h5>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-2">
                                                                    <div className="col-6">
                                                                        <h4>Phone</h4>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <h5>03001234567</h5>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-2">
                                                                    <div className="col-6">
                                                                        <h4>Address</h4>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <h5>Rahim Yar Khan</h5>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-2">
                                                                    <div className="col-6">
                                                                        <h4>Country</h4>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <h5>Pakistan</h5>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-2">
                                                                    <div className="col-6">
                                                                        <h4>State</h4>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <h5>US</h5>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-2">
                                                                    <div className="col-6">
                                                                        <h4>City</h4>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <h5>Rahim Yar Khan</h5>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-2">
                                                                    <div className="col-6">
                                                                        <h4>Postal Code</h4>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <h5>64200</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>{/* .card-preview */}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className='col-12'>
                                                        <div className="card card-preview" style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', outline: 'none', marginTop: '20px' }}>
                                                            <div className="card-header">
                                                                <h4 className="py-2 page-title" style={{ color: "#9d72ff" }}>Products Ordered</h4>
                                                            </div>
                                                            <div className='card-body'>
                                                                <table className="table table-bordered table-hover table-striped table-responsive-sm">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Id#</th>
                                                                            <th>Image</th>
                                                                            <th>Name</th>
                                                                            <th>Price</th>
                                                                            <th>Quantity</th>
                                                                            <th>Sub Total</th>
                                                                            <th>Detail</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>

                                                                        {orderProducts && orderProducts.map((item) => (
                                                                            <tr>
                                                                                <td>{item.id}</td>
                                                                                <td><img src={MyConstants.ImageUrl + `${item.image}`} style={Image} /></td>
                                                                                <td>{item.name}</td>
                                                                                <td>{item.price}</td>
                                                                                <td>{item.order_product_qnty}</td>
                                                                                <td>{item.sub_total}</td>
                                                                                <td>{item.detail}</td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                                <Row>
                                                                    <Col></Col>
                                                                    <Col className="mt-3">
                                                                        <h2>Total Due</h2>
                                                                        <hr />
                                                                        <h4 className="ml-3">Shipping Charges <span className="font-weight-medium float-right mr-3">Rs. 00</span></h4>
                                                                        <hr />
                                                                        <h4 className="ml-3">Total <span className="font-weight-medium float-right mr-3">Rs. {total.total}</span></h4>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </div>{/* .card-preview */}
                                                    </div>
                                                </div>
                                            </div> {/* nk-block */}
                                        </div>{/* .components-preview */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* content @e */}
                        {/* Footer */}
                        <div className="nk-footer">
                            <Footer />
                        </div>
                        {/* footer @e */}
                    </div>
                    {/* wrap @e */}
                </div>
                {/* main @e */}
            </div>
            {/* nk-app-root */}
        </div>
    )
}
