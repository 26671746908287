import React from 'react'

export default function Border() 
{
    // Border
    const Border = 
    {
        height: '250px',
        borderRadius: '10px',
        background: '#eee',
        marginTop: '40px',
        borderTop: '10px solid lightgreen',
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.3), 0 6px 20px 0 rgba(0,0,0,0.19)'
    }

    return (
        <div>
            <div className='container' style={Border}>

            </div>
        </div>
    )
}
