import axios from 'axios';
import React, { useState } from 'react'

export default function WeatherApi() 
{
    const MainBg = 
    {
        height: "300px",
        width: "450px",
        backgroundColor: "#ffc20b",
        fontSize: "25px",
        borderRadius: "10px",
        padding: "30px 50px"
    }

    const [temperature, setTemperature] = useState("");
    const [city, setCity] = useState("Rahim Yar Khan");
    const [country, setCountry] = useState("Pakistan");
    const [desc, setDesc] = useState("");

    const GetWeatherData = async (city, country) => 
    {
        axios(
        {
            method: "GET",
            url: `http://api.openweathermap.org/data/2.5/weather?q=${city},${country}&APPID=ffc3ed6398ea3f19b460ac48e9ca21c6`,
        }).then((response) => {
            console.log(response.data.main.temp);
            setTemperature(response.data.main.temp - 273.15);
            setDesc(response.data.weather[0].main)
        }).catch((error) => {
            console.log(error);
        });
    };

    return (
        <div>
            <div className="nk-app-root">
                {/* main @s */}
                <div className="nk-main ">
                    {/* wrap @s */}
                    <div className="nk-wrap nk-wrap-nosidebar">
                        {/* content @s */}
                        <div className="nk-content ">
                            <div className="nk-block nk-block-middle nk-auth-body  wide-xs" style={ MainBg }>
                                <div className="nk-block-head">
                                    <div className="nk-block-head-content">
                                        <div className="nk-block-des">
                                            <h3 className='my-3'>{new Date().toLocaleString()}</h3>
                                        </div>
                                        <h3 className="nk-block-title font-weight-bold">{city} Weather</h3>
                                        <div className="nk-block-des">
                                            <h3>{Math.round(temperature * 100) / 100} ℃ - {desc}</h3>
                                        </div>
                                    </div>
                                </div>
                                <form className="form-validate is-alter" >
                                    <div className="form-group">
                                        <input type="text" className="form-control" value={city} onChange={(e)=> setCity(e.target.value)} />
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control" value={country} onChange={(e)=> setCountry(e.target.value)} />
                                    </div>
                                    <div className="form-group d-none">
                                        <button className="btn btn-md font-weight-bold btn-dark btn-block" onClick={GetWeatherData(city, country)} >Get Data</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        {/* wrap @e */}
                    </div>
                    {/* content @e */}
                </div>
                {/* main @e */}
            </div>
            {/* app-root @e */}
          </div>
    )
}
