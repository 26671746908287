import PropTypes from "prop-types";
import React, { useEffect, Suspense, lazy } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
// import { multilanguage, loadLanguages } from "redux-multilanguage";
import { connect } from "react-redux";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";
import Categories from "./components/Dahboard/Pages/Categories/Categories";
import SubCategories from "./components/Dahboard/Pages/SubCategories/SubCategories";
import Brands from "./components/Dahboard/Pages/Brands/Brands";
import Units from "./components/Dahboard/Pages/Units/Units";
import Variants from "./components/Dahboard/Pages/Variants/Variants";
import GeneralSettings from "./components/Dahboard/Pages/GeneralSettings/GeneralSettings";
import Currency from "./components/Dahboard/Pages/Currency/Currency";
import TextRates from "./components/Dahboard/Pages/TextRates/TextRates";
import Stores from "./components/Dahboard/Pages/Stores/Stores";
import ListProducts from "./components/Dahboard/Pages/Products/ListProducts";
import AddProducts from "./components/Dahboard/Pages/Products/AddProducts";
import EditProducts from "./components/Dahboard/Pages/Products/EditProducts";
import TaggedProducts from "./components/Dahboard/Pages/Products/TaggedProducts";
import ListUsers from "./components/Dahboard/Pages/Users/ListUsers";
import AddUsers from "./components/Dahboard/Pages/Users/AddUsers";
import EditUsers from "./components/Dahboard/Pages/Users/EditUsers";
import ListCustomers from "./components/Dahboard/Pages/Customers/ListCustomers";
import AddCustomers from "./components/Dahboard/Pages/Customers/AddCustomers";
import EditCustomers from "./components/Dahboard/Pages/Customers/EditCustomers";
import ListSuppliers from "./components/Dahboard/Pages/Suppliers/ListSuppliers";
import EditSuppliers from "./components/Dahboard/Pages/Suppliers/EditSuppliers";
import AddSuppliers from "./components/Dahboard/Pages/Suppliers/AddSuppliers";
import ListSales from "./components/Dahboard/Pages/Sales/ListSales";
import AddSales from "./components/Dahboard/Pages/Sales/AddSales";
import EditSales from "./components/Dahboard/Pages/Sales/EditSales";
import ListPurchase from "./components/Dahboard/Pages/Purchase/ListPurchase";
import AddPurchase from "./components/Dahboard/Pages/Purchase/AddPurchase";
import EditPurchase from "./components/Dahboard/Pages/Purchase/EditPurchase";
import ListExpense from "./components/Dahboard/Pages/Expenses/ListExpense";
import AddExpense from "./components/Dahboard/Pages/Expenses/AddExpense";
import EditExpense from "./components/Dahboard/Pages/Expenses/EditExpense";
import Login from "./components/Dahboard/Login";
import WeatherApi from "../src/WeatherApi";
// Border
import Border from "./components/Border";
import Create_request from './components/CreateRequest/Create_request'
import DetailContainer from "./pages/containers/DetailContainer";
import CartContainer from "./pages/containers/CartContainer";
import carts from "./pages/carts/Carts";
import ListOrders from "./components/Dahboard/Pages/Orders/ListOrders";
import OrdersDetail from "./components/Dahboard/Pages/Orders/OrdersDetail";
import Dashview from "./components/Dahboard/Pages/Dashview/index";
import ProductList from "./ProductList";
import Accounts from "./components/Dahboard/Pages/Accounts";
import AddAccount from "./components/Dahboard/Pages/Accounts/AddAccount";
import EditAccount from "./components/Dahboard/Pages/Accounts/EditAccount";
import FrontProductsList from "./pages/Products/FrontProductsList";
import CheckOut from "./pages/containers/CheckOutContainer";

// Honme
const HomeFashion = lazy(() => import("./pages/home/HomeFashion"));

// Shop
const ShopGridStandard = lazy(() => import("./pages/shop/ShopGridStandard"));

// product pages
const Product = lazy(() => import("./pages/shop-product/Product"));

// blog pages
const BlogStandard = lazy(() => import("./pages/blog/BlogStandard"));
const BlogDetailsStandard = lazy(() =>
  import("./pages/blog/BlogDetailsStandard")
);

// other pages
const About = lazy(() => import("./pages/other/About"));
const Contact = lazy(() => import("./pages/other/Contact"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));

const Cart = lazy(() => import("./pages/other/Cart"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Compare = lazy(() => import("./pages/other/Compare"));
// const Checkout = lazy(() => import("./pages/other/Checkout"));

const NotFound = lazy(() => import("./pages/other/NotFound"));

const App = (props) => {

  return (
    <ToastProvider placement="bottom-left">
      <BreadcrumbsProvider>
        <Router>
          <ScrollToTop>
            <Suspense
              fallback={
                <div className="flone-preloader-wrapper">
                  <div className="flone-preloader">
                    <span></span>
                    <span></span>
                  </div>
                </div>
              }
            >
              <Switch>

                <Route path='/dashboard' component={Dashview} />

                {/* Border */}
                <Route
                  exact
                  path="/border"
                  component={Border}
                />

                {/* Weather Api */}
                <Route
                  exact
                  path="/weather-api"
                  component={WeatherApi}
                />

                {/* Craete Request*/}
                <Route
                  exact
                  path="/create-request"
                  component={Create_request}
                />

                {/* Login */}
                <Route
                  exact
                  path="/login"
                  component={Login}
                />

                {/* Categories */}
                <Route
                  exact
                  path="/categories"
                  component={Categories}
                />

                {/* SubCategories */}
                <Route
                  exact
                  path="/sub-categories"
                  component={SubCategories}
                />

                {/* Brands */}
                <Route
                  exact
                  path="/brands"
                  component={Brands}
                />

                {/* Units */}
                <Route
                  exact
                  path="/units"
                  component={Units}
                />

                {/* Variants */}
                <Route
                  exact
                  path="/variants"
                  component={Variants}
                />

                {/* GeneralSettings */}
                <Route
                  exact
                  path="/general-settings"
                  component={GeneralSettings}
                />

                {/* Currency */}
                <Route
                  exact
                  path="/currency"
                  component={Currency}
                />

                {/* TextRates */}
                <Route
                  exact
                  path="/text-rates"
                  component={TextRates}
                />

                {/* Stores */}
                <Route
                  exact
                  path="/stores"
                  component={Stores}
                />

                {/* ListProducts */}
                <Route
                  exact
                  path="/list-products"
                  component={ListProducts}
                />

                {/* AddProducts */}
                <Route
                  exact
                  path="/add-product"
                  component={AddProducts}
                />

                {/* EditProducts */}
                <Route
                  exact
                  path="/edit-product/:id"
                  component={EditProducts}
                />

                {/* TaggedProducts */}
                <Route
                  exact
                  path="/tagg-product"
                  component={TaggedProducts}
                />

                {/* Listusers */}
                <Route
                  exact
                  path="/list-users"
                  component={ListUsers}
                />

                {/* AddUsers */}
                <Route
                  exact
                  path="/add-users"
                  component={AddUsers}
                />

                {/* EditUsers */}
                <Route
                  exact
                  path="/edit-users"
                  component={EditUsers}
                />

                {/* ListCustomers */}
                <Route
                  exact
                  path="/list-customers"
                  component={ListCustomers}
                />

                {/* AddCustomers */}
                <Route
                  exact
                  path="/add-customers"
                  component={AddCustomers}
                />

                {/* EditCustomers */}
                <Route
                  exact
                  path="/edit-customers/"
                  component={EditCustomers}
                />

                {/* ListSuppliers */}
                <Route
                  exact
                  path="/list-suppliers"
                  component={ListSuppliers}
                />

                {/* AddCustomers */}
                <Route
                  exact
                  path="/add-suppliers"
                  component={AddSuppliers}
                />

                {/* EditSuppliers */}
                <Route
                  exact
                  path="/edit-suppliers/"
                  component={EditSuppliers}
                />

                {/* ListSales */}
                <Route
                  exact
                  path="/list-sales/"
                  component={ListSales}
                />

                {/* AddSales */}
                <Route
                  exact
                  path="/add-sales/"
                  component={AddSales}
                />

                {/* EditSales */}
                <Route
                  exact
                  path="/edit-sales/"
                  component={EditSales}
                />

                {/* ListPurchase */}
                <Route
                  exact
                  path="/list-purchase/"
                  component={ListPurchase}
                />

                {/* AddPurchase */}
                <Route
                  exact
                  path="/add-purchase/"
                  component={AddPurchase}
                />

                {/* EditPurchase */}
                <Route
                  exact
                  path="/edit-purchase/"
                  component={EditPurchase}
                />

                {/* ListExpense */}
                <Route
                  exact
                  path="/list-expense/"
                  component={ListExpense}
                />

                {/* AddExpense */}
                <Route
                  exact
                  path="/add-expense/"
                  component={AddExpense}
                />

                {/* EditExpense */}
                <Route
                  exact
                  path="/edit-expense/"
                  component={EditExpense}
                />

                {/* EditExpense */}
                <Route
                  exact
                  path="/product-detail/:id"
                  component={DetailContainer}
                />

                {/* ListOrders */}
                <Route
                  exact
                  path="/list-orders"
                  component={ListOrders}
                />

                {/* OrdersDetail */}
                <Route
                  exact
                  path="/orders-detail/:id"
                  component={OrdersDetail}
                />

                {/* ListAccounts */}
                <Route
                  exact
                  path="/list-accounts"
                  component={Accounts}
                />

                {/* AddAccount */}
                <Route
                  exact
                  path="/add-account"
                  component={AddAccount}
                />

                {/* EditAccount */}
                <Route
                  exact
                  path="/edit-account"
                  component={EditAccount}
                />

                {/* ProductsList */}
                <Route
                  path={process.env.PUBLIC_URL + '/front-products-list/:id'}
                  component={FrontProductsList}
                />

                <Route
                  exact
                  path="/carts/"
                  component={CartContainer}
                />

                <Route
                  exact
                  path="/checkout/"
                  component={CheckOut}
                />

                {/* Home */}
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/"}
                  component={HomeFashion}
                />

                {/* Shop */}
                <Route
                  path="/shop-grid-standard"
                  component={ProductList}
                />

                {/* Shop product pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/product/:id"}
                  render={(routeProps) => (
                    <Product {...routeProps} key={routeProps.match.params.id} />
                  )}
                />

                {/* Blog */}
                <Route
                  path={process.env.PUBLIC_URL + "/blog-standard"}
                  component={BlogStandard}
                />

                {/* Blog Details */}
                <Route
                  path={process.env.PUBLIC_URL + "/blog-details-standard"}
                  component={BlogDetailsStandard}
                />

                {/* About Us */}
                <Route
                  path={process.env.PUBLIC_URL + "/about"}
                  component={About}
                />

                {/* Conatct Us */}
                <Route
                  path={process.env.PUBLIC_URL + "/contact"}
                  component={Contact}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/my-account"}
                  component={MyAccount}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/login-register"}
                  component={LoginRegister}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/cart"}
                  component={Cart}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/wishlist"}
                  component={Wishlist}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/compare"}
                  component={Compare}
                />
                {/* <Route
                  path={process.env.PUBLIC_URL + "/checkout"}
                  component={Checkout}
                /> */}

                <Route
                  path={process.env.PUBLIC_URL + "/not-found"}
                  component={NotFound}
                />

                <Route exact component={NotFound} />
              </Switch>
            </Suspense>
          </ScrollToTop>
        </Router>
      </BreadcrumbsProvider>
    </ToastProvider>
  );
};

// App.propTypes = {
//   dispatch: PropTypes.func
// };

export default App;
// connect()(multilanguage(App));
