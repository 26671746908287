import React, { useState, useEffect } from 'react'
import * as MyConstants from '../../../../Constant/Config'

export default function AddTaggs({ closeModal, edit }) {
    const Outline =
    {
        boxShadow: 'none',
        borderColor: '#9d72ff',
        backgroundColor: 'transparent'
    }

    // Get Products Data 
    const [Products, setProducts] = useState([]);
    useEffect(() => {
        fetch(MyConstants.listProduct,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((result) => 
        {
            result.json().then((response) => 
            {
                console.warn(response);
                if(response.status == true)
                {
                    setProducts(response.products);
                }
            })
        });
    },[]);

    const [product, setProduct] = useState("");
    const [discount, setDiscount] = useState("");
    const [popular, setPopular] = useState("");
    const [featured, setFeatured] = useState("");

    // checkedPopular
    const checkedPopular = (e) => {
        const checked = e.target.checked;
        if(checked)
        {
            setPopular('Popular');
        }
        else{
            setPopular("");
        }
    }

    // checkedFeatured
    const checkedFeatured = (e) => {
        const checked = e.target.checked;
        if(checked)
        {
            setFeatured('Featured');
        }
        else{
            setFeatured("");
        }
    }


    // AddData
    const AddData = (e) => {
        let data = {"product": product, "popular": popular, "featured": featured, "discount": discount};
        fetch(MyConstants.addTaggedProduct,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then((result) => {
                result.json().then((response) => {
                    if (response.status == true) {
                        closeModal(true)
                        edit();
                    }
                })
            })
    }


    return (
        <div>
            <div className="form-group" >
                <label className="form-label" htmlFor="">Products</label>
                <span style={{ color: 'red', marginLeft: '2px' }}>*</span>
                <select className='form-control custom-select' onChange={(e) => setProduct(e.target.value)}>
                    <option>Select Product Name</option>
                    {Products.map(item =>
                        <option value={item.id}>{item.name}</option>
                    )}
                </select>
            </div>

            <div className="form-group" >
                <label className="form-label" htmlFor="discount">Discounts</label>
                <span style={{ color: 'red', marginLeft: '2px' }}>*</span>
                <div className="form-control-wrap">
                    <input type="number" className="form-control" name="name" placeholder="Enter Discount" style={Outline} onChange={(e) => setDiscount(e.target.value)} required />
                </div>
            </div>

            <div className='row mb-3'>
                <div className='col'>
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="popular" onChange={checkedPopular} />
                        <label className="custom-control-label" htmlFor="popular">
                            <h4>Popular</h4>
                        </label>
                    </div>
                </div>
                <div className='col'>
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="featured" onChange={checkedFeatured} />
                        <label className="custom-control-label" htmlFor="featured">
                            <h4>Featured</h4>
                        </label>
                    </div>
                </div>
            </div>

            <div className="modal-footer bg-white"></div>
            <div className="form-group float-right">
                <button className="btn btn-sm btn-danger mr-2" onClick={closeModal}>Cancel</button>
                <button className="btn btn-sm btn-primary" style={{ backgroundColor: "#9d72ff", border: "#9d72ff", outline: "none", boxShadow: "none" }} onClick={AddData}>Add Data</button>
            </div>
        </div>
    )
}
