import React, {useState} from 'react'
import { useHistory } from 'react-router-dom';
import * as MyConstants from '../../../../Constant/Config' 

export default function AddUnits({closeModal, edit}) 
{
    const Outline = 
    {
        boxShadow: 'none',
        borderColor: '#9d72ff',
        backgroundColor: 'transparent'
    }

    const [ name, setName ] = useState("");
    const [ code, setCode ] = useState("");

    // AddUnit
    const History = useHistory();
    const navigate = () => History.push('');
    const AddUnit = (e) => 
    {
        let data = {"name": name,"code": code};
        fetch(MyConstants.addUnit,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then((result) => 
        {
            result.json().then((response) => 
            {
                if(response.status == true)
                {
                    closeModal(true);
                    edit();
                }
            })
        })
    }

    
    return (
        <div>
            <div className="form-group" >
                <label className="form-label" htmlFor="unit_code">Unit Code</label>
                <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                <div className="form-control-wrap">
                    <input type="text" className="form-control" name="code" placeholder="Enter Unit Code" style={Outline} onChange={ (e)=>setCode(e.target.value) } required />
                </div>
            </div>
            
            <div className="form-group" >
                <label className="form-label" htmlFor="unit_name">Unit Name</label>
                <span style={{color: 'red', marginLeft: '2px'}}>*</span>
                <div className="form-control-wrap">
                    <input type="text" className="form-control" name="name" placeholder="Enter Unit Name" style={Outline} onChange={ (e)=>setName(e.target.value) } required />
                </div>
            </div>

            <div className="modal-footer bg-white"></div>
            <div className="form-group float-right">
                <button className="btn btn-sm btn-danger mr-2" onClick={closeModal}>Cancel</button>
                <button className="btn btn-sm btn-primary" style={{ backgroundColor: "#9d72ff", border: "#9d72ff", outline: "none", boxShadow: "none" }} onClick={AddUnit}>Add Unit</button>
            </div>
        </div>
    )
}
